import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import Cleave from 'cleave.js/react';
import Helper from 'Helper';
import { API_BASE } from 'API';


const ModalPagamento = () => {
  const [value, setValue] = useState('');

  useEffect(() => {
    // Nenhum valor inicial necessário aqui, será definido ao abrir o modal
  }, []);

  const showAlert = (forma_cod, initialValue, anotherValue) => {
    const formattedValue = initialValue; // Formata o valor inicial
    swal({
      text: 'Digite um valor em '+anotherValue,
      content: {
        element: 'input',
        attributes: {
          type: 'text',
          id: 'cleave-input',
          value: formattedValue, // Define o valor inicial no input
        },
      },
      buttons: {
        cancel: {
          text: 'Fechar',
          value: null,
          visible: true,
          className: 'btn-danger btnDanger', // Adiciona a classe personalizada
          closeModal: true,
        },
        insert: {
            text: 'Salvar',
            value: 'insert',
            className: 'btnBlack', // Adiciona a classe personalizada
          },
      },
      closeOnClickOutside: false, // Evita fechar clicando fora
    }).then((buttonValue) => {
      if (buttonValue === 'insert' || buttonValue === 'balance') {
          let cleaveValue = document.getElementById('cleave-input').value;
        // Remover pontos de milhar
         
          let valorLimpo = cleaveValue;
          // Substituir vírgula decimal por ponto
          valorLimpo = valorLimpo.replace(',', '.');
          const valorNumerico = parseFloat(valorLimpo);
          const numericValue = cleaveValue;
          
          if(sessionStorage.getItem('md') == 'dev'){
            console.log('Envio Caixa:', API_BASE+"processaPgto.php?access_token="+sessionStorage.getItem("token")+"&cod_inscricao="+sessionStorage.getItem("cod_inscricao")+"&id_user="+sessionStorage.getItem('nome_usser')+"&cod_usser="+sessionStorage.getItem('cod_usser')+"&valor="+valorLimpo+"&cod_forma="+forma_cod);
          }
          fetch(API_BASE+"processaPgto.php?access_token="+sessionStorage.getItem("token")+"&cod_inscricao="+sessionStorage.getItem("cod_inscricao")+"&id_user="+sessionStorage.getItem('nome_usser')+"&cod_usser="+sessionStorage.getItem('cod_usser')+"&valor="+valorLimpo+"&cod_forma="+forma_cod)
                .then((response) => response.json())
                .then((resultado) => {
                    if(sessionStorage.getItem('md') == 'dev'){
                        console.log('Resposta processaPgto:', resultado);
                    }
                  if(resultado.cod_inscricao !=""){
                    if(undefined != resultado.saldo && null != resultado.saldo){
                      sessionStorage.setItem("saldo",resultado.saldo);
                      sessionStorage.setItem("total_inscricao",resultado.total_inscricao);
                      sessionStorage.setItem("pago",resultado.total_pago);
                      Helper.CalculaTotais();
                    }
                    swal('Inserido', `Valor inserido: R$ ${numericValue}`, 'success');       
                  }
                  else{
                   return false;   
                  }
              })
              .catch((error) => {
                  console.error(error);
                  return false; 
              });


         
          /* const valorNumerico = parseFloat(valorLimpo);
          const numericValue = cleaveValue;
        if (isNaN(valorLimpo) || valorLimpo <= 0) {
          swal('Erro', 'Por favor, insira um valor maior que zero.', 'error');
        } else {
          let saldo_anterior = sessionStorage.getItem("saldo");
          let total_pago =  sessionStorage.getItem("total_pago");
          sessionStorage.setItem("total_pago",(parseFloat(total_pago)+parseFloat(valorLimpo)));
          sessionStorage.setItem("saldo",(parseFloat(saldo_anterior)+parseFloat(valorLimpo)));
          let lancamento_anterior = sessionStorage.getItem("lancamento");
          if(undefined == lancamento_anterior || null == lancamento_anterior || "null" == lancamento_anterior || "" == lancamento_anterior){
            lancamento_anterior="";
          }
          let lancamento = lancamento_anterior+"<p class='text-left'>"+anotherValue+": "+"R$ "+(valorLimpo*100/100).toLocaleString('pt-br', {minimumFractionDigits: 2})+"</p>";
          sessionStorage.setItem("lancamento",lancamento);
          Helper.CalculaTotais();
          Helper.RetiraExcluir();
          swal('Inserido', `Valor inserido: R$ ${numericValue}`, 'success');
         */
      } else {
          return false;
      }
    });
  };
  
  return showAlert;
};

export default ModalPagamento;
