import React, { useState } from "react";
import swal from "sweetalert";
import {API_BASE} from "./API";
import editar from "./img/editar.jpg";
import excluir from "./img/excluir.jpg";
import carrinho from "./img/carrinho.png";
import loading from "./img/Loading_2.gif";
import 'tailwindcss/tailwind.css';
import ModalPagamento from "Components/ModalPagamento";

function IsData(id){

  /******** VALIDA DATA NO FORMATO DD/MM/AAAA *******/
  let stringData = document.getElementById(id).value;  
  let splitData= [];
  let camps =  document.getElementById(id);
  let data_tp = camps.getAttribute("data-tipo");


  if(stringData.length > 0){
          if(stringData.length != 10)
          {
              swal("Data Inválida","","error");
              return false;
          }
 
  let regExpCaracter = /[^\d]/;     //Expressão regular para procurar caracter não-numérico.
  let regExpEspaco = /^\s+|\s+$/g;  //Expressão regular para retirar espaços em branco.

  if(stringData.length != 10)
  {
      swal("Data Inválida","","error");
      return false;
  }

  splitData = stringData.split('/');

  if(splitData.length != 3)
  {                 
      swal("Data Inválida","","error");
      return false;
  }

  /* Retira os espaços em branco do início e fim de cada string. */
  splitData[0] = splitData[0].replace(regExpEspaco, '');
  splitData[1] = splitData[1].replace(regExpEspaco, '');
  splitData[2] = splitData[2].replace(regExpEspaco, '');

  if ((splitData[0].length != 2) || (splitData[1].length != 2) || (splitData[2].length != 4))
  {                   
      swal("Data Inválida","","error");
      return false;
  }

  /* Procura por caracter não-numérico. EX.: o "x" em "28/09/2x11" */
  if (regExpCaracter.test(splitData[0]) || regExpCaracter.test(splitData[1]) || regExpCaracter.test(splitData[2]))
  {                    
      swal("Data Inválida","","error");
      return false;
  }

  let dia = parseInt(splitData[0],10);
  let mes = parseInt(splitData[1],10)-1; //O JavaScript representa o mês de 0 a 11 (0->janeiro, 1->fevereiro... 11->dezembro)
  let ano = parseInt(splitData[2],10);    
  let novaData = new Date(ano, mes, dia);                                    
  let hoje = new Date();              

  if (data_tp == 'DATA_NASC' && novaData > hoje) {
          swal("Data informada não pode ser maior que a data de hoje","","error");
          return false;
      }
      
  
  if ((novaData.getDate() != dia) || (novaData.getMonth() != mes) || (novaData.getFullYear() != ano))
  {       
      swal("Data Inválida","","error");             
      return false;
  }
  else
  {                 
      return true;
  }
}

}

async  function validaInscricao(caminho){  
    if(ValidaSessao('') == false){
      return false;
    }
    
    document.getElementById("avancar_cadastro").style.display="none"
    document.getElementById("loading").innerHTML="<img src="+loading+" class='loaling' />";
    let validacao = true;
    let validacaox;
    let elementos_campos = document.getElementsByClassName('campos');
    let modo_campo = document.getElementById("modo_campo").value;
    let tx_modo_campo = document.getElementById('tx_modo_campo').innerHTML;
    if(tx_modo_campo == "Sintético"){
      modo_campo = "analitico";
    }
  
    
    let validacao_campos = [];
    let monta_envio_campos = [];
    let campos_prontos = [];
    let vl;
    let cpp;
    let accumulator;
    let uniqueData;

    Array.prototype.forEach.call(elementos_campos, function(item) { 
      vl="";
      if(item.getAttribute('data_'+modo_campo) == 'S'){
            if(item.getAttribute('data-tipo') === "DATA"  || item.getAttribute('data-tipo') === 'DATA_NASC'){
              vl = item.value.replace(/[^0-9]/g,'');
                if((item.getAttribute('data_obrigatorio') == 'S' && item.value !="") || (item.getAttribute('data_obrigatorio') == 'N' && item.value !="" || (item.getAttribute('data_obrigatorio') == 'S' && item.value ==""))){
                        validacaox = IsData(item.getAttribute('id'));
                        if(!validacaox){
                            validacao_campos.push("<li>"+item.getAttribute('title')+" inválida</li>");
                            validacao = false;
                            item.classList.add("obrigatorio");
                            vl = "";

                        }
                        else{
                          item.classList.remove("obrigatorio");
                        }
                 }
                 else{
                        vl = "";
                        item.classList.remove("obrigatorio");
                 }

                 monta_envio_campos.push({
                  cod_campo: item.getAttribute('data-cod_campo'), 
                  campo: item.getAttribute('data-campo'), 
                  tipo : item.getAttribute('data-tipo'),
                  value: FormataData('US', item.value)
                });
       
      }else if(item.getAttribute('data-tipo') === "EMAIL"){
                if((item.getAttribute('data_obrigatorio') == 'S' && item.value !="") || (item.getAttribute('data_obrigatorio') == 'N' && item.value !="" || (item.getAttribute('data_obrigatorio') == 'S' && item.value ==""))){
                                  validacaox = IsEmail(item.value);
                                  if(!validacaox){
                                    validacao_campos.push("<li>"+item.getAttribute('title')+" inválido</li>");
                                    validacao = false;
                                    item.classList.add("obrigatorio");
                                    vl = "";
                                  }else{
                                    vl = item.value;
                                    item.classList.remove("obrigatorio");
                                  }
                        
                                  if((item.value.length < item.getAttribute('data-min') || item.value.length > item.getAttribute('data-max')))
                                  {
                                    validacao_campos.push("<li>"+item.getAttribute('title')+" maior ou menor que o permitido</li>");
                                    validacao = false;
                                    item.classList.add("obrigatorio");  
                                    vl = "";   
                                  }
                                  else{
                                    vl = item.value;
                                    item.classList.remove("obrigatorio");
                                  }

                }
                    monta_envio_campos.push({
                      cod_campo: item.getAttribute('data-cod_campo'), 
                      campo: item.getAttribute('data-campo'), 
                      tipo : item.getAttribute('data-tipo'),
                      value: vl
                    });
                

      }else if(item.getAttribute('data-tipo') === "CELULAR_BR" || item.getAttribute('data-tipo') === "FONE_BR"){
        let cel_br = (null != item.value && item.value !=""  ? item.value : '');
        vl = cel_br.replace(/[^0-9]/g,'');
        if((item.getAttribute('data_obrigatorio') == 'S' && cel_br !="") || (item.getAttribute('data_obrigatorio') == 'N' && cel_br !="") || (item.getAttribute('data_obrigatorio') == 'S' && cel_br =="")){
                if((parseInt(vl.length) < parseInt(item.getAttribute('data-min'))) || (parseInt(vl.length) > parseInt(item.getAttribute('data-max'))) || vl == "" 
                || vl =="99999999999" || vl =="88888888888" || vl =="11111111111" || vl =="22222222222" || vl =="33333333333" || vl =="44444444444" || vl =="55555555555" || vl =="66666666666" || vl =="77777777777"
                || vl =="99999999999" || vl =="88888888888" || vl =="1111111111" || vl =="2222222222" || vl =="3333333333" || vl =="4444444444" || vl =="5555555555" || vl =="6666666666" || vl =="7777777777"
                ){
                    validacao_campos.push("<li>"+item.getAttribute('title')+" inválido</li>");
                    validacao = false;  
                    item.classList.add("obrigatorio");
                    vl="";
                }
                else{
                  item.classList.remove("obrigatorio");
                }
        }
       
          item.classList.remove("obrigatorio");
          monta_envio_campos.push({
            cod_campo: item.getAttribute('data-cod_campo'), 
            campo: item.getAttribute('data-campo'), 
            tipo : item.getAttribute('data-tipo'),
            value: vl
          });
        
        
    }else if(item.getAttribute('data-tipo') === "CNPJ" || item.getAttribute('data-tipo') === "CPF" ){
               
      vl = (null != item.getAttribute('value') && item.getAttribute('value') !=""  ? item.getAttribute('value') : '');
      vl = vl.replace(/[^0-9]/g,'');
      if((item.getAttribute('data_obrigatorio') == 'S' && vl !="") || (item.getAttribute('data_obrigatorio') == 'N' && vl !="" || (item.getAttribute('data_obrigatorio') == 'S' && vl ==""))){
            if(vl != "" && (parseInt(vl.length) < parseInt(item.getAttribute('data-min')) || parseInt(vl.length) > parseInt(item.getAttribute('data-max')))){
                  validacao_campos.push("<li>"+item.getAttribute('title')+" inválido</li>");
                  validacao = false;
                  item.classList.add("obrigatorio");  
                  vl="";
              }
              else{
                    item.classList.remove("obrigatorio");
                      if(undefined !== vl &&  parseInt(vl.length)  > 11){
                          validacaox = isValidCNPJ(vl, item.getAttribute('data_obrigatorio'));
                          if(!validacaox){
                              validacao_campos.push("<li>"+item.getAttribute('title')+" inválido</li>");
                              validacao = false;
                              item.classList.add("obrigatorio");
                              vl="";
                          }
                      }
                      else{
                          validacaox = isValidCPF(vl, item.getAttribute('data_obrigatorio'));
                          if(!validacaox){
                              validacao_campos.push("<li>"+item.getAttribute('title')+" inválido</li>");
                              validacao = false;
                              item.classList.add("obrigatorio");
                              vl="";
                          }
                      }
                      
              }

              monta_envio_campos.push({
                cod_campo: item.getAttribute('data-cod_campo'), 
                campo: item.getAttribute('data-campo'), 
                tipo : item.getAttribute('data-tipo'),
                value: vl
               });
      }
      else{
        monta_envio_campos.push({
          cod_campo: item.getAttribute('data-cod_campo'), 
          campo: item.getAttribute('data-campo'), 
          tipo : item.getAttribute('data-tipo'),
          value: ""
         });
        item.classList.remove("obrigatorio");
      }

     
  
  }else if(item.getAttribute('data-tipo') === "CEP" || item.getAttribute('data-tipo') === "NUMERICO" ){
          vl = (null != item.getAttribute('value') && item.getAttribute('value') !="" ? item.getAttribute('value') : '');
          if((item.getAttribute('data_obrigatorio') == 'S' && vl !="") || (item.getAttribute('data_obrigatorio') == 'N' && vl !="") || (item.getAttribute('data_obrigatorio') == 'S' && vl =="")){
                  vl = vl.replace(/[^0-9]/g,'');
                    if((item.getAttribute('data-tipo') === "NUMERICO"  && parseInt(vl.length) < parseInt(item.getAttribute('data-min')) || (parseInt(vl.length) > parseInt(item.getAttribute('data-max')) && item.getAttribute('data-tipo') != "CEP") || (parseInt(vl.length) != 8 && item.getAttribute('data-tipo') === "CEP"))){
                        validacao_campos.push("<li>"+item.getAttribute('title')+" invalido</li>");
                        validacao = false;
                        item.classList.add("obrigatorio");  
                        vl="";
                    }
                    else{
                        item.classList.remove("obrigatorio");
                    }
            }

            monta_envio_campos.push({
              cod_campo: item.getAttribute('data-cod_campo'), 
              campo: item.getAttribute('data-campo'), 
              tipo : item.getAttribute('data-tipo'),
              value: vl
            });
          
   

}else if(item.getAttribute('data-tipo') === "RADIO" || item.getAttribute('data-tipo') === "CHECKBOX" || item.getAttribute('data-tipo') === "TERMO"){
                    
  let checkBoxes = document.getElementsByName(item.getAttribute('name'));
  let selecionados = 0;
  checkBoxes.forEach(function(el) {
      
    if(el.checked) {
        selecionados++;
    }
    
  });
  if(item.getAttribute('data_obrigatorio') == 'S' && (parseInt(selecionados) < parseInt(item.getAttribute('data-min')) || parseInt(selecionados) > parseInt(item.getAttribute('data-max')))){
      validacao_campos.push("<li>"+document.getElementById('RC'+item.getAttribute('name')).getAttribute('title')+", precisa ser selecionado no mínimo:"+parseInt(item.getAttribute('data-min'))+" e no máximo: "+parseInt(item.getAttribute('data-max'))+"</li>");
      validacao = false;
      cpp = document.getElementById('RC'+item.getAttribute('name'));
      cpp.classList.add("obrigatorio2");
  }
  else if(parseInt(selecionados) > 0){

                  if(parseInt(selecionados) >= parseInt(item.getAttribute('data-min')) && parseInt(selecionados) <= parseInt(item.getAttribute('data-max'))){

                                cpp = document.getElementById('RC'+item.getAttribute('name'));
                                cpp.classList.remove("obrigatorio2");
                                    if(item.checked)
                                      {
                                            monta_envio_campos.push({
                                                  cod_campo: item.getAttribute('data-cod_campo'), 
                                                  campo: item.getAttribute('data-campo'), 
                                                  tipo : item.getAttribute('data-tipo'),
                                                  value: item.value
                                              });
                                      }
                    }
                    else{
                      validacao_campos.push("<li>"+document.getElementById('RC'+item.getAttribute('name')).getAttribute('title')+", precisa ser selecionado no mínimo:"+parseInt(item.getAttribute('data-min'))+" e no máximo: "+parseInt(item.getAttribute('data-max'))+"</li>");
                      validacao = false;
                      cpp = document.getElementById('RC'+item.getAttribute('name'));
                      cpp.classList.add("obrigatorio2");
                    }
         }
         else{
          let exists = monta_envio_campos.some(item2 => item2.cod_campo === item.getAttribute('data-cod_campo'));
              if(!exists && parseInt(selecionados)<=0){
                monta_envio_campos.push({
                  cod_campo: item.getAttribute('data-cod_campo'), 
                  campo: item.getAttribute('data-campo'), 
                  tipo : item.getAttribute('data-tipo'),
                  value: ""
                });
            } 
          cpp = document.getElementById('RC'+item.getAttribute('name'));
          cpp.classList.remove("obrigatorio2"); 
         }
         

}else if(item.getAttribute('data-tipo') === "COMBO"){
  
  if((undefined != item.value && null != item.value && item.value != "") && (item.getAttribute('data_obrigatorio') == 'S' && item.value !="") || (item.getAttribute('data_obrigatorio') == 'N' && item.value !="" || (item.getAttribute('data_obrigatorio') == 'S' && item.value =="")))
      {   
             if(item.value == ""){
              validacao_campos.push("<li>"+item.getAttribute('title')+" obrigatório</li>");
              validacao = false;
              item.classList.add("obrigatorio");    
             }
             else{
              item.classList.remove("obrigatorio");   
              }
      }
      
      
      monta_envio_campos.push({
        cod_campo: item.getAttribute('data-cod_campo'), 
        campo: item.getAttribute('data-campo'), 
        tipo : item.getAttribute('data-tipo'),
        value: item.value
      });

}else{

      if((undefined != item.value && null != item.value && item.value != "") && (item.getAttribute('data_obrigatorio') == 'S' && item.value !="") || (item.getAttribute('data_obrigatorio') == 'N' && item.value !="" || (item.getAttribute('data_obrigatorio') == 'S' && item.value =="")))
      {   
            if((item.value.length < item.getAttribute('data-min') || item.value.length > item.getAttribute('data-max')))
              {
                validacao_campos.push("<li>"+item.getAttribute('title')+" maior ou menor que o permitido</li>");
                validacao = false;
                item.classList.add("obrigatorio");    
              }
              else{
                item.classList.remove("obrigatorio");   
              }
         
        }
        
        monta_envio_campos.push({
          cod_campo: item.getAttribute('data-cod_campo'), 
          campo: item.getAttribute('data-campo'), 
          tipo : item.getAttribute('data-tipo'),
          value: item.value
        });

  }
      
}

monta_envio_campos = monta_envio_campos.reduce((accumulator, current) => {
  if (!accumulator.some(item => item.cod_campo === current.cod_campo && item.valor === current.value)) {
      accumulator.push(current);
  }
  return accumulator;
}, []);
                   
    });

  
    
    if(undefined == document.getElementById("tipo_credencial").value || null == document.getElementById("tipo_credencial").value || document.getElementById("tipo_credencial").value == ""){
      validacao_campos.push("<li>Tipo Credencial obrigatório</li>");
      validacao = false;
      document.getElementById("tipo_credencial").classList.add("obrigatorio");
    }
    else{
         document.getElementById("tipo_credencial").classList.remove("obrigatorio");   
    }

    var validacao_campos_limpo = validacao_campos.filter(function(este, i) {
      return validacao_campos.indexOf(este) === i;
    });

    if(!validacao){
      document.getElementById("avancar_cadastro").style.display="block";
      document.getElementById("loading").innerHTML="";
      const wrapper = document.createElement('div');
      wrapper.innerHTML = "<ul className='error_list'>"+validacao_campos_limpo.join('\n')+"</ul>";
      swal({
        title: "Corriga os campos conforme o aviso abaixo",
        content: wrapper,
        className: "alert_title", 
        icon: "error"
      });
     
     }
     else{
      let foto =  sessionStorage.getItem("foto");
      if(undefined == foto || null == foto || foto == ""){
        if(undefined != document.getElementById("foto")?.getAttribute("data-arquivo") && null != document.getElementById("foto")?.getAttribute("data-arquivo")){
          foto =  document.getElementById("foto").getAttribute("data-arquivo"); 
        }else{
          foto = "";
        }
      }
      if(undefined == foto || null == foto || foto == ""){
        foto = "";
      }
     
      campos_prontos = {
        'tipo': "participante", 
        'tipo_credencial': document.getElementById("tipo_credencial").value,
        'foto':foto,
        'cod_inscricao': document.getElementById("cod_inscricao").value, 
        'cod_empresa': document.getElementById("cod_empresa").value,
        'cod_usser': sessionStorage.getItem('cod_usser'),          
        "campos":  
             monta_envio_campos
    };

    let cod_inscricao = document.getElementById("cod_inscricao").value;
    let cod_empresa = document.getElementById("cod_empresa").value;
     
    
      if(!validaToken()){
        document.getElementById("loading").innerHTML="";
        swal("Token Expirado, acesse o link novamente","","error");
        return false;
      }

      if(validaLogin()){
        
        EnviarCadastro(JSON.stringify(campos_prontos), cod_inscricao, cod_empresa, sessionStorage.getItem('cod_usser'), caminho);
      } 
      else{
        document.getElementById("loading").innerHTML="";
        swal("Login Expirado, se logue e tente novamente","","error").then(()=>{
          AbreLogin();
        })
       
      }
    }
    
    
}

function AbreModal(dados){
    const wrapper = document.createElement('div');
    const tabelaHTML = `
    <p>Histórico</p>
    <textarea class='campo_texto_historico' id="campo_texto_historico"></textarea><br />
    <button class='btn_add_historico'>Adicionar +</button> <br /><br />
      <table class="table_historico">
        <thead>
          <tr class="campo_texto_historico_tr">
            <th></th>
            <th></th>
            <th>Histórico</th>
            <th>Data</th>
            <th>Usuário</th>
            <th>Código</th>
          </tr>
        </thead>
        <tbody>
          ${dados.map(item => `
            <tr class="campo_texto_historico_tr">
              <td><input type="checkbox" /></td>
              <td><img src=${editar} title="Editar" /></td>
              <td>${item.historico}</td>
              <td>${item.data}</td>
              <td>${item.usuario}</td>
              <td>${item.cod_historico}</td>
            </tr>
          `).join('')}
          <tr>
              <td></td>
              <td><img src=${excluir} title="Excluir" /></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
        </tbody>
      </table>
    `;
    
    wrapper.innerHTML =tabelaHTML;
    swal({
      title: "Código: - Nome do Inscrito - Histórico",
      content: wrapper,
      className: "table_historico", 
      icon: "",
      button:"Fechar"
    });
    document.getElementById("campo_texto_historico").focus();
}

function AbreLogin() {
  swal({
    title: "Login",
    text: "Por favor, digite seu login e senha:",
    content: {
      element: "div",
      attributes: {
        innerHTML: `
          <div>
            <input id="login-input" class="swal-input border border-gray-300" placeholder="Login" type="text"><div class="br_celular"></div>
            <input id="password-input" class="swal-input border border-gray-300" placeholder="Senha" type="password">
          </div>
        `,
      },
    },
    buttons: {
      confirm: {
        text: "Entrar",
        id:"btn_entrar_pesquisa",
        value: true,
        visible: true,
        className: "",
        closeModal: false,
      },
    },
    closeOnClickOutside: false, // Impede o fechamento ao clicar fora do modal
    closeOnEsc: false,           // Impede o fechamento ao pressionar a tecla ESC
  }).then(async (willLogin) => {
   
    
    if (willLogin) {
      const login = document.getElementById('login-input').value;
      const password = document.getElementById('password-input').value;
      if (!login || !password) {
        swal({
          title: "Erro",
          text: "Por favor, digite login e senha.",
          icon: "error",
          button: "OK",
        }).then(() => {
          AbreLogin(); // Reabre o modal de login
        });

        
      } else {

        
            let url = API_BASE+'login.php?user='+login+'&senha='+password+'&access_token='+sessionStorage.getItem('token');
            const response =  await fetch(url);
            const text =  await response.text(); // Obtenha a resposta como texto
            // Remove o BOM se presente
            const cleanText = text.replace(/^\uFEFF/, '');
            const resultado = JSON.parse(cleanText);

            if(sessionStorage.getItem('md') == 'dev'){
                console.log('RespostA Login:', resultado);
            }

          if(response && resultado.msg == "OK"){
            sessionStorage.setItem('cod_usser', resultado.cod_usser);
            sessionStorage.setItem('nome_usser', login);
            sessionStorage.setItem('nome_evento',resultado.evento+" ("+resultado.cod_evento+")");
            sessionStorage.setItem("usser_ok", "05121986");
            if(undefined != document.getElementById("pais") && document.getElementById("pais")){
              let lista_pais = resultado.paises;
              sessionStorage.setItem('lista_paises',JSON.stringify(lista_pais));
               // Obter o elemento select
            var select = document.getElementById('pais');
                  lista_pais.map((item) => {
                  
                  // Criar uma nova opção
                  var novaOpcao = document.createElement('option');

                  // Definir o valor e o texto da nova opção
                  novaOpcao.value = item.id_pais;
                  novaOpcao.text = item.pais;

                  // Adicionar a nova opção ao elemento select
                  select.add(novaOpcao);


                });
            }
            swal.stopLoading();
            swal.close();

            // Garantindo o foco no campo de CPF após o fechamento do modal
            setTimeout(() => {
              const loginInput = document.getElementById('cpf');
              if (loginInput) {
                loginInput.focus();
              }
            }, 0);
          }
          else{
              swal({
                title: "Erro",
                text: resultado.msg,
                icon: "error",
                button: "Voltar",
              }).then(() => {
                AbreLogin(); // Reabre o modal de login
              });
          }
     


       
      }
    }
  

  }).catch(err => {
    if (err) {
      swal({
        title: "Erro",
        text: "Erro na entrada.",
        icon: "error",
        button: "Voltar",
      }).then(() => {
        AbreLogin(); // Reabre o modal de login
      });
    }
  });

  // Garantindo o foco no campo de login após o modal ser exibido
  setTimeout(() => {
    document.querySelector(".swal-button--confirm").id = "btn_entrar_pesquisa";
    const loginInput = document.getElementById('login-input');
    if (loginInput) {
      loginInput.focus();
    }

    document.addEventListener('keypress', function(event) {
      if (event.key === 'Enter') {
            const btnEntrarPesquisa = document.getElementById('btn_entrar_pesquisa');
            if (btnEntrarPesquisa) {
                btnEntrarPesquisa.click();
            } else {
                //alert('Informe o login e senha');
            }
        }
      });

   
  }, 0);

  

}

function IsEmail(email){
  var re = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
  var vl = re.test(email);
       
  
      if(!email || email.indexOf('@')==-1 || email.indexOf('.')==-1 || !vl)
       {    
         return false;
       }
       else{
           return true;
       }
  }

  function isValidCPF(cpf, obrig) {
    // Validar se é String

    if (cpf =='' && obrig == 'N') return true;
    if (cpf =='' || undefined == cpf || cpf.length == 0) { 
        return false
    }
    // Tirar formatação
    cpf = cpf.replace(/[^0-9]/g,'');
    
    // Validar se tem tamanho 11 ou se é uma sequência de digitos repetidos
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false
    
    // String para Array
    cpf = cpf.split('')
    
    const validator = cpf
        // Pegar os últimos 2 digitos de validação
        .filter((digit, index, array) => index >= array.length - 2 && digit)
        // Transformar digitos em números
        .map( el => +el )
        
    const toValidate = pop => cpf
        // Pegar Array de items para validar
        .filter((digit, index, array) => index < array.length - pop && digit)
        // Transformar digitos em números
        .map(el => +el)
    
    const rest = (count, pop) => (toValidate(pop)
        // Calcular Soma dos digitos e multiplicar por 10
        .reduce((soma, el, i) => soma + el * (count - i), 0) * 10) 
        // Pegar o resto por 11
        % 11 
        // transformar de 10 para 0
        % 10
        
    return !(rest(10,2) !== validator[0] || rest(11,1) !== validator[1])
}

function isValidCNPJ(cnpj, obrig) {
    if (cnpj =='' && obrig == 'N') return true;
    cnpj = cnpj.replace(/[^0-9]/g,'');
    if(undefined == cnpj || cnpj == '' || cnpj.length == 0) return false;
    if (cnpj.length != 14){
        return false;
    }
 
    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" || 
        cnpj == "11111111111111" || 
        cnpj == "22222222222222" || 
        cnpj == "33333333333333" || 
        cnpj == "44444444444444" || 
        cnpj == "55555555555555" || 
        cnpj == "66666666666666" || 
        cnpj == "77777777777777" || 
        cnpj == "88888888888888" || 
        cnpj == "99999999999999")
        return false;  
    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;
         
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
     resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
          return false;
           
    return true;
}
function EnviarCadastro(dados, cod_inscricao, cod_empresa, cod_usser, caminho){
      var requestOptions = {
        method: 'POST',
        body:'[' + dados + ']',
        redirect: 'follow'
    };

    if(sessionStorage.getItem('md') == 'dev'){
        console.log('Cadastro Envio:','[' + dados + ']');
        console.log('Url Cadastro:',API_BASE+"processaCadastro.php?access_token="+sessionStorage.getItem("token")+"&cod_inscricao="+cod_inscricao+"&cod_empresa="+cod_empresa+"&id_user="+sessionStorage.getItem('nome_usser')+"&cod_usser="+sessionStorage.getItem('cod_usser')+"&email="+sessionStorage.getItem('email')+"&pais="+sessionStorage.getItem("pais")+"&cpf="+sessionStorage.getItem("cpf"));
    }

    fetch(API_BASE+"processaCadastro.php?access_token="+sessionStorage.getItem("token")+"&cod_inscricao="+cod_inscricao+"&cod_empresa="+cod_empresa+"&id_user="+sessionStorage.getItem('nome_usser')+"&cod_usser="+sessionStorage.getItem('cod_usser')+"&email="+sessionStorage.getItem('email')+"&pais="+sessionStorage.getItem("pais")+"&cpf="+sessionStorage.getItem("cpf"), requestOptions)
        .then((response) => response.json())
        .then((resultado) => {
            if(sessionStorage.getItem('md') == 'dev'){
                console.log('Resposta Cadastro:', resultado);
            }
        if(resultado.cod_inscricao != ""){
           sessionStorage.setItem('cod_inscricao',resultado.cod_inscricao);
           document.getElementById('tx_cod_inscricao').innerHTML = resultado.cod_inscricao;
           swal("Dados gravados com sucesso","","success").then(()=>{
                if(undefined == caminho || null == caminho || caminho == "" || caminho == "3"){
                  window.location.href="/VendaRapida"; 
                }
                else{
                  window.location.href="/Inscricao"; 
                }
                
            });
                 
        }
        else{   
                document.getElementById("loading").innerHTML="";
                document.getElementById("avancar_cadastro").style.display="block";
                swal("Não foi possível efetuar o cadastro","","error");
                return false;
            }
        
        })
        .catch((error) => {
            document.getElementById("loading").innerHTML="";
            document.getElementById("avancar_cadastro").style.display="block";
            swal("Não foi possível efetuar o cadastro, retorno não encontrado","","error");
            console.error(error);
            return false;
            
        
        });
        
}

function FormataData(tipo, valor){
  let formato = "";
  
  if(tipo =="BR"){
      valor = valor.replace(" 00:00:00",""); 
      valor = valor.split("-");
      let formato_brasil = valor[2]+valor[1]+valor[0];
      formato = formato_brasil.replace("NaN","");
  }
  else{       
      let vl_n = valor.split("/");
      let formato_americano = vl_n[2]+"-"+vl_n[1]+"-"+vl_n[0];
      valor = formato_americano.replace(" ","");
      formato = formato_americano;
  }
 
 
  return formato;
}

function FiltraCampos(){
  let cl = document.getElementsByClassName("divs");
  let elemento;
  let procura = document.getElementById('modo_campo').value;
  let tx_botao;

  if(procura == "sintetico"){
     procura = "analitico";
     tx_botao = "Sintético";
    
  }
  else{
    procura = "sintetico";
    tx_botao = "Analítico";
  }
  document.getElementById('modo_campo').value = procura;
  document.getElementById("tx_modo_campo").innerHTML = tx_botao;

  Array.prototype.forEach.call(cl, function(item) { 
    if(item.getAttribute('class').includes(procura)){
        item.style.display = "";
    }
    else{
      item.style.display = "none";
    }
     
        if(procura == "analitico"){
          item.classList.remove('esconde_primeiro');
        }
        else if(procura == "sintetico" && item.getAttribute('class').includes('analitico') && !item.getAttribute('class').includes('sintetico')){
          //console.log(item.getAttribute('data-c'),item.getAttribute('class'),item.getAttribute('class').includes('analitico'));
          item.classList.add('esconde_primeiro');
        }
  });

  
}

function FModalPagamento(saldo_devedor,forma_pg){
  
  ModalPagamento();

}

function EmContrucao(){
  alert('Em Construção');
}

function validaLogin(){
  if(undefined == sessionStorage.getItem('cod_usser') || null == sessionStorage.getItem('cod_usser') || "" == sessionStorage.getItem('cod_usser')){
      return false;
  }
  else{
    return true;
  }

}

function validaToken(){
  if(undefined == sessionStorage.getItem('token') || null == sessionStorage.getItem('token') || "" == sessionStorage.getItem('token')){
    return false;
}
else{
  return true;
}
}


function AbreModalProdutos(categoria){
  let textoCategoria = categoria.options[categoria.selectedIndex].text;
  let cod_categoria = categoria.value;
  let retorno = "" ;
  let i = 0;
  if(sessionStorage.getItem('md') == 'dev'){
     console.log('Chamada GetProdutos:',API_BASE+'getProdutos.php?cod_categoria='+cod_categoria+'&cod_inscricao='+sessionStorage.getItem('cod_inscricao')+'&access_token='+sessionStorage.getItem('token')+"&id_user="+sessionStorage.getItem('nome_usser')+"&cod_usser="+sessionStorage.getItem('cod_usser'));
  }
  fetch(API_BASE+'getProdutos.php?cod_categoria='+cod_categoria+'&cod_inscricao='+sessionStorage.getItem('cod_inscricao')+'&access_token='+sessionStorage.getItem('token')+"&id_user="+sessionStorage.getItem('nome_usser')+"&cod_usser="+sessionStorage.getItem('cod_usser'))
        .then((response) => response.json())
        .then((resultado) => {
            if(sessionStorage.getItem('md') == 'dev'){
                console.log('Resposta getProdutos:', resultado);
            }
            retorno =' <div class="div_grupo_produtos_pai">';
            Array.prototype.forEach.call(resultado.data, function(g) { 
                
         // if(resultado.msg == "OK"){
           
           
            retorno +='<p  class="text-left text-grupo">'+g.grupo+'</p>';

            Array.prototype.forEach.call(g.produtos, function(p) { 
             
                  i++;
                  retorno +='<div class="flex border rounded-md espaco_top20 tm95" title="'+p.descricao+'">';
                        retorno +='<div class="item_produto">';
                          retorno +='<table class="text-left item_atividade_celular">';
                          retorno +='<tr><td>('+p.cod_prod+') '+p.produto+'</td></tr>';
                          retorno +='<tr><td class="item_atividade_celular_palestrante">'+p.palestrantes+'</td></tr>';
                          retorno +='<tr><td>Data: '+p.data_hora+'</td></tr>';
                          retorno +='<tr><td>Local: '+p.local+'</td></tr>';
                          retorno +=' </table>';
                        retorno +='</div>';
                      retorno +='<div class="item_produto2">';
                        retorno +='<table class="item_atividade_celular">';
                        retorno +='<tr><td class="text-sky-700"><b>Vagas</b></td></tr>';
                        retorno +='<tr><td id="q_btn00'+i+'" data-qtd="'+p.vagas+'" data-titulo="'+p.produto+'" data-preco="'+p.preco+'" data-palestrante="'+p.palestrantes+'" data-codprod="'+p.cod_prod+'">'+p.vagas+'</td></tr>';
                        retorno +='</table>';
                      retorno +='</div>';
                      retorno +='<div class="item_produto3">';
                        retorno +='<table class="item_atividade_celular">';
                        retorno +='<tr><td>R$ '+(parseFloat(p.preco)*100/100).toLocaleString('pt-br', {minimumFractionDigits: 2})+'</td></tr>';
                        if(p.incluso){
                           retorno +='<tr><td><button class="btnRed2 text-white w-20 btn_prod_venda" id="btn00'+i+'" data-produto="'+p.cod_prod+'">Remover</button></td></tr>';
                        }
                        else{
                          retorno +='<tr><td><button class="bg-yellow-400 text-white w-20 btn_prod_venda" id="btn00'+i+'" data-produto="'+p.cod_prod+'">Adicionar</button></td></tr>';
                        }
                        retorno +='</table>';
                      retorno +='</div>';
                  retorno +='</div>';

            });
          

          });
          retorno +='</div>';
          // exibe os produtos

          const wrapper = document.createElement('div');
  const tabelaHTML = `
<div class="text-center text-base tm100p h30"><b>Lista de Produtos</b></div>
  <div class="flex tm100p h50">
  
            <div class="w-full p-1 text-left">
              <input type="text" class="border cupom_celular f12_celular" id="cupom" placeholder="Cupom promocional">
              <button class="bg-orange-600 px-4 py-2 text-white font-bold text-center cupom_celular f12_celular">Validar</button>
            </div>
            
            <div class="w-1/10 p-1 tm100p">
              <table class="table w-full text-right">
                <tr>
                  <td class="text-right cupom_celular"><input type="text" value="0" class="tm20" id="qtd_carrinho" /><span class="f12_celular">Itens selecionados</span> </td>
                  <td class="text-right"><img src=${carrinho} /></td>
                </tr> 
              </table>   
            </div>
            
             
      </div> 
       <div class="espaco_topo_20_celular"></div>
  <div class="w-full text-center h20 espaco_topo_10">Categoria: ${textoCategoria}</div>
  <br />
      ${retorno}

    
  `;
  
  wrapper.innerHTML = tabelaHTML;

  
  swal({
    title: "",
    content: wrapper,
    className: "div_grupo_produtos", 
    icon: "",
    buttons: {
      /*confirm: {
        text: "Incluir itens no pedido",
        id:"btn_incluir_pedido",
        value: true,
        visible: true,
        className: "",
        closeModal: false,
      },*/
        cancel: {
          text: "Fechar",
          value: null,
          visible: true,
          className: "",
          closeModal: true,  // Fecha o modal ao clicar no botão de cancelar
        }
    },
    closeOnClickOutside: false, // Impede o fechamento ao clicar fora do modal
    closeOnEsc: false           // Impede o fechamento ao pressionar a tecla ESC   
  }).then(() => {
     //window.location.reload(); michael
  });


  setTimeout(() => {
    const buttons = wrapper.querySelectorAll('.btn_prod_venda');
    let produto ;
    buttons.forEach(button => {
      button.addEventListener('click', () => {
        if(button.textContent == 'Adicionar'){
          produto = button.getAttribute('data-produto');
          if(AddAtividade(produto,"ins")){
            button.textContent = 'Remover';
            button.style.backgroundColor  = 'red';
            document.getElementById("q_"+button.id).innerHTML = (parseInt(document.getElementById("q_"+button.id).getAttribute("data-qtd")) - 1);
            document.getElementById("q_"+button.id).setAttribute("data-qtd",(parseInt(document.getElementById("q_"+button.id).getAttribute("data-qtd")) - 1));
            }
       }
        else{
          produto = button.getAttribute('data-produto');
          if(AddAtividade(produto,"del")){
              button.textContent = 'Adicionar';
              button.style.backgroundColor  = 'rgb(250 204 21)';
              document.getElementById("q_"+button.id).innerHTML = (parseInt(document.getElementById("q_"+button.id).getAttribute("data-qtd")) + 1);
              document.getElementById("q_"+button.id).setAttribute("data-qtd",(parseInt(document.getElementById("q_"+button.id).getAttribute("data-qtd")) + 1));
          }
        }
        
        CalculaCarrinho();
      });
    });
  }, 0);
          // fim de exibir produtos

        //} 0800 726 0101
         
      })
      .catch((error) => {
          console.error(error);
          
      });
 

  

  
 


}

function CalculaCarrinho(){
  let qtd_carrinho = 0;
 
  const buttons = document.querySelectorAll('.btn_prod_venda');
  buttons.forEach(button => {
      if(button.textContent == "Remover"){
        qtd_carrinho++;
      }
  });
 
  document.getElementById("qtd_carrinho").value = qtd_carrinho;

}


function RemoveItem(campo){
  alert(campo);
}

function CalculaTotais(){
  let valor = 0;
  let sb = "";
  let cls1 = "txred";
  let cls2 = "txblue";
  let total  = sessionStorage.getItem("total_pago");

  const buttons_ex = document.querySelectorAll('.excluir');
  buttons_ex.forEach(button_e => {
            valor += parseFloat(button_e.getAttribute("data-preco"));
  })
  if(document.getElementById("total") && sessionStorage.getItem("total_inscricao")){
    document.getElementById("total").value = "R$ "+(parseFloat(sessionStorage.getItem("total_inscricao"))*100/100).toLocaleString('pt-br', {minimumFractionDigits: 2});
    document.getElementById("total").setAttribute("data-valor",parseFloat(sessionStorage.getItem("total_inscricao")));
  }

  if(parseFloat(sessionStorage.getItem("saldo"))){
    sb = " -";
    cls1 = "txblue";
    cls2 = "txred";
  }
  else{
    sb = "";
    cls1 = "txred";
    cls2 = "txblue";
  }
  if(document.getElementById("saldo")){
    let valorLimpoSaldo =  sessionStorage.getItem("saldo");
    valor = valorLimpoSaldo;
    if(valor >= 0){
      sb = "";
      cls1 = "txred";
      cls2 = "txblue";
    }
    document.getElementById("saldo").value = "R$ "+(parseFloat(valor)*100/100).toLocaleString('pt-br', {minimumFractionDigits: 2});
    document.getElementById("saldo").setAttribute("value","R$ "+(parseFloat(valor)*100/100).toLocaleString('pt-br', {minimumFractionDigits: 2}));

    document.getElementById("saldo").setAttribute("data-valor",parseFloat(valor));
    document.getElementById("saldo").classList.remove(cls1);
    document.getElementById("saldo").classList.add(cls2);
  }

  if(sessionStorage.getItem("pago") &&  parseFloat(sessionStorage.getItem("pago")) > 0){
      document.getElementById("pago").value = "R$ "+((parseFloat(sessionStorage.getItem("pago")))*100/100).toLocaleString('pt-br', {minimumFractionDigits: 2});
      document.getElementById("pago").setAttribute("data-valor",parseFloat(sessionStorage.getItem("pago")));
    }
    else{
      document.getElementById("pago").value = "R$ 0,00";
      document.getElementById("pago").setAttribute("data-valor","0");
    }

    let total_f = document.getElementById("total").value.replace(/[^0-9]/g,'');
    let total_pg = document.getElementById("pago").value.replace(/[^0-9]/g,'');
    let saldo = document.getElementById("saldo").value.replace(/[^0-9]/g,'');
   
    if(total_f == total_pg && saldo>=0){
      //RetiraExcluir();
    }
  
}
function Sair(){
  sessionStorage.setItem('email','');
  sessionStorage.setItem('pais','');
  sessionStorage.setItem('cpf','');
  sessionStorage.setItem('pais','');
  sessionStorage.setItem('cpf','');
  sessionStorage.setItem('cod_usser','');
  sessionStorage.setItem('nome_usser','');
  sessionStorage.setItem("usser_ok", '');
  window.location.href="/?id="+sessionStorage.getItem("token")+"=&md="+sessionStorage.getItem("md");
}

async function VerificaLancamentos(){
  const wrapper = document.createElement('div');
  let tabelaHTML;  
    try {
      const response = await fetch(API_BASE + "getCaixa.php?access_token=" + sessionStorage.getItem("token") + "&cod_inscricao=" + sessionStorage.getItem("cod_inscricao") + "&id_user=" + sessionStorage.getItem('nome_usser') + "&cod_usser=" + sessionStorage.getItem('cod_usser'));
      const text = await response.text(); // Obtenha a resposta como texto
      // Remove o BOM se presente
      const cleanText = text.replace(/^\uFEFF/, '');
      const resultado = JSON.parse(cleanText);
      let lancamento = "<div class='tbl_c'><table class='tbl'><tr><td><b>Forma</b></td><td><b>Valor</b></td><td><b>Data</b></td><td><b>Usuário</b></td></tr>";
      Array.prototype.forEach.call(resultado.caixa, function(c) { 
          lancamento = lancamento+"<tr class='f12_celular'><td class='tbltd'>"+c.formapgto+"</td><td class='tbltd'>"+"R$ "+(c.valor*100/100).toLocaleString('pt-br', {minimumFractionDigits: 2})+"</td><td class='tbltd'>"+c.data+"</td><td class='tbltd'>"+c.user+"</td></tr>";
      });
       lancamento = lancamento+"</table></div>";
       tabelaHTML = lancamento
    }
    catch(error){
      console.log(error);
    }
    wrapper.innerHTML = tabelaHTML;
    swal({
      title: "Lançamentos",
      content: wrapper,
      className: "", 
      icon: "",
      button:"Fechar"
    });

    
}

function RetiraExcluir(){
  const buttons_ex = document.querySelectorAll('.excluir');
  buttons_ex.forEach(button_e => {
           button_e.remove();
  })
}

function LimpaCarrinho(){
  const buttons_ex = document.querySelectorAll('.excluir');
  let id;
      buttons_ex.forEach(button_e => {
              id = "tb_"+button_e.getAttribute("data-id");
              if(document.getElementById(id)){
                document.getElementById(id).remove();
              }
      })
}

function ValidaSessao(pg){
  let vl = true;
  if(!validaToken()){
    swal("Token Expirado, acesse o link principal novamente","","error");
    vl = false;
  }
  if(!validaLogin()){
    swal("Login Expirado, se logue novamente","","error");
    if(AbreLogin()){
    if(pg == 3 || pg == 4){
      if(pg == 3){
        window.location.href= '/VendaRapida';
      }
      if(pg == 4){
        window.location.href= '/Inscricao';
      }
     
    }else{
      vl = false;
    }
  }
    
  }
  return vl;
}

async function AddAtividade(atv, tipo) {
  let cod_categoria = document.getElementById("categoria").value;
  
  if (undefined == cod_categoria || null == cod_categoria || undefined == sessionStorage.getItem("cod_inscricao") || null == sessionStorage.getItem("cod_inscricao") || "" == sessionStorage.getItem("cod_inscricao") || sessionStorage.getItem("cod_inscricao") <= 0) {
    return false;
  }

  if ((!sessionStorage.getItem("cod_inscricao") || sessionStorage.getItem("cod_inscricao") == "") || (!sessionStorage.getItem("token") || sessionStorage.getItem("token") == "") || (!atv || atv == "") || tipo == "" || sessionStorage.getItem('nome_usser') == "" || sessionStorage.getItem('cod_usser') == "") {
    /*console.log('cod_inscricao:', sessionStorage.getItem("cod_inscricao"));
    console.log('token:', sessionStorage.getItem("token"));
    console.log('atv:', atv);
    console.log('tipo:', tipo);
    console.log('id_user:', sessionStorage.getItem('nome_usser'));
    console.log('cod_usser:', sessionStorage.getItem('cod_usser'));
    console.log('cod_categoria:', cod_categoria);*/
    return false;
  } else {
    if (sessionStorage.getItem('md') == 'dev') {
      console.log('Chamada processaProd:', API_BASE + "processaProd.php?access_token=" + sessionStorage.getItem("token") + "&cod_inscricao=" + sessionStorage.getItem("cod_inscricao") + "&cod_prod=" + atv + "&acao=" + tipo + "&id_user=" + sessionStorage.getItem('nome_usser') + "&cod_usser=" + sessionStorage.getItem('cod_usser') + "&cod_categoria=" + cod_categoria);
    }

  
    try {
      const response = await fetch(API_BASE + "processaProd.php?access_token=" + sessionStorage.getItem("token") + "&cod_inscricao=" + sessionStorage.getItem("cod_inscricao") + "&cod_prod=" + atv + "&acao=" + tipo + "&id_user=" + sessionStorage.getItem('nome_usser') + "&cod_usser=" + sessionStorage.getItem('cod_usser') + "&cod_categoria=" + cod_categoria);
      const text = await response.text(); // Obtenha a resposta como texto
      // Remove o BOM se presente
      const cleanText = text.replace(/^\uFEFF/, '');
      const resultado = JSON.parse(cleanText);
      
      if (sessionStorage.getItem('md') == 'dev') {
        console.log('Resposta add/del prod:', resultado);
      }

      if (resultado.cod_inscricao != ""){
       
          if(undefined != resultado.produtos && null != resultado.produtos && resultado.produtos !=""){
            let html_item = ProdutosAnteriores(resultado.produtos,3);
            document.getElementById("caixa_atividades").innerHTML = "";
            document.getElementById("caixa_atividades").innerHTML = html_item;

          }
          else{
            document.getElementById("caixa_atividades").innerHTML = "";
          
          }

          if(undefined != resultado.saldo && null != resultado.saldo){
            sessionStorage.setItem("saldo",resultado.saldo);
            sessionStorage.setItem("total_inscricao",resultado.total_inscricao);
            sessionStorage.setItem("pago",resultado.total_pago);
            CalculaTotais();
          }


        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}

function CampoReferencia(campo , id_referencia){
  let campo_principal =  document.getElementById(campo);
  let campo_referencia =  "campo"+id_referencia;
  campo_referencia = document.getElementById(campo_referencia);

 
  if(undefined != campo_principal &&  null != campo_principal && (campo_principal.value == null || campo_principal.value =="")){
              let campo_tamanho =  campo_principal.getAttribute('maxlenght'); 
            
              if(undefined != campo_referencia && null != campo_referencia && campo_referencia.value != ""){
                  var texto = campo_referencia.value;
                  if(texto && texto.length > 3){
                      var primeira = texto.substring(0, (texto + " ").indexOf(" "));
                      var ultima = texto.split(" ");
                      let nome_resumido = primeira + " "+ultima[(ultima.length - 1)];
                      campo_principal.value = nome_resumido;
                  }
          }
  }
 
  
}

function buscaProdutos(cod_categoria){
  let retorno ;
  let i = 0;
  if(sessionStorage.getItem('md') == 'dev'){
    console.log('chamada GetProdutos:',API_BASE+'getProdutos.php?cod_categoria='+cod_categoria+'&cod_inscricao='+sessionStorage.getItem('cod_inscricao')+'&access_token='+sessionStorage.getItem('token')+"&id_user="+sessionStorage.getItem('nome_usser')+"&cod_usser="+sessionStorage.getItem('cod_usser'));
  }
  fetch(API_BASE+'getProdutos.php?cod_categoria='+cod_categoria+'&cod_inscricao='+sessionStorage.getItem('cod_inscricao')+'&access_token='+sessionStorage.getItem('token')+"&id_user="+sessionStorage.getItem('nome_usser')+"&cod_usser="+sessionStorage.getItem('cod_usser'))
        .then((response) => response.json())
        .then((resultado) => {
            if(sessionStorage.getItem('md') == 'dev'){
                console.log('Resposta getProdutos:', resultado);
            }

            Array.prototype.forEach.call(resultado.data, function(g) { 
                
         // if(resultado.msg == "OK"){
           
            retorno =' <div class="div_grupo_produtos_pai">';
            retorno +='<p  class="text-left">'+g.grupo+'</p>';

            Array.prototype.forEach.call(g.produtos, function(p) { 
              i++;
                  retorno +='<div class="flex border rounded-md espaco_top20 tm95">';
                  retorno +='<div class="w-3/4">';
                  retorno +='<table class="text-left item_atividade_celular">';
                  retorno +='<tr><td>('+p.cod_prod+') '+p.produto+'</td></tr>';
                  retorno +='<tr><td class="item_atividade_celular_palestrante">'+p.palestrantes+'</td></tr>';
                  retorno +='<tr><td>Data: '+p.data_hora+'</td></tr>';
                  retorno +='<tr><td>Local: '+p.local+'</td></tr>';
                  retorno +=' </table>';
                  retorno +='</div>';
                  retorno +='<div class="w-1/10 p-4">';
                  retorno +='table class="item_atividade_celular">';
                  retorno +='<tr><td class="text-sky-700 text-white">Vagas</td></tr>';
                  retorno +='<tr><td id="q_btn00'+i+'" data-qtd="'+p.vagas+'" data-titulo="O Futuro do Render" data-preco="'+p.preco+'" data-palestrante="Ander Alencar">'+p.vagas+'</td></tr>';
                  retorno +='</table>';
                  retorno +='</div>';
                  retorno +='<div class="w-3/20 p-4">';
                  retorno +='<table class="item_atividade_celular">';
                  retorno +='<tr><td>R$ '+(parseFloat(p.preco)*100/100).toLocaleString('pt-br', {minimumFractionDigits: 2})+'</td></tr>';
                        if(p.incluso){
                           retorno +='<tr><td><button class="btnRed2 text-white w-20 btn_prod_venda" id="btn00'+i+'" data-produto="'+p.cod_prod+'">Remover</button></td></tr>';
                        }
                        else{
                          retorno +='<tr><td><button class="bg-yellow-400 text-white w-20 btn_prod_venda" id="btn00'+i+'" data-produto="'+p.cod_prod+'">Adicionar</button></td></tr>';
                        }
                  retorno +='</div>';
                  retorno +='</div>';

            });
            retorno +='</div>';

          });

        
          return retorno;
          //}
         
      })
      .catch((error) => {
          console.error(error);
          
      });

}

function ProdutosAnteriores(prods,pg){
let i = 1000;
let html_item = "";
let texto = "";
let preco ="0";
let saldo;
let total;
  
 Array.prototype.forEach.call(prods, function(p) { 
    i++;
        html_item += "<table class='table item_carrinho' id='tb_"+p.cod_prod+"'>"
        texto = "";
        preco = "";
        texto += "<p class='texto_atividade'>("+p.cod_prod+") "+p.produto+"</p>";
        texto += "<p class='texto_palestrante'>"+p.palestrantes+"</p>";
        preco = p.preco;
        if(pg != 4){
          html_item += "<tr><td class='w30'><img src="+excluir+" title='Excluir' class='excluir' id='btn_e"+p.cod_prod+"' data-produto='"+p.cod_prod+"' data-id='tb_"+p.cod_prod+"' data-preco='"+preco+"' /></td><td class='descricao_item_celular'>"+texto+"</td><td class='text-right preco_celular'><b>R$ "+(parseFloat(preco)*100/100).toLocaleString('pt-br', {minimumFractionDigits: 2})+"</b></td></tr>";
        }
        else{
          html_item += "<tr><td class='w30'></td><td class='descricao_item_celular'>"+texto+"</td><td class='text-right preco_celular'><b>R$ "+(parseFloat(preco)*100/100).toLocaleString('pt-br', {minimumFractionDigits: 2})+"</b></td></tr>";
        }
        html_item += "</table>";

  })

  
  setTimeout(() => {
    const buttons = document.querySelectorAll('.excluir');
    let produto ;
    buttons.forEach(button => {
      button.addEventListener('click', () => {
        produto = button.getAttribute('data-produto');
        if(undefined != produto && null != produto && produto !="")
          if(AddAtividade(produto,"del")){

          }
          else{

          }
      });
    });
  });
  

  return html_item;
  
  
}

function AvisaCategoria(){
  let categoria = document.getElementById("categoria");
  let selectedOption = categoria.options[categoria.selectedIndex];
  let comprovante = "N";
  let texto = "";
  
  
  if(undefined != categoria && null != categoria){
    comprovante = selectedOption.getAttribute('data-comprovante');
    texto = selectedOption.getAttribute('data-texto');
  }

  if(comprovante == "S"){
    swal("Solicite o comprovante da Categoria "+texto,"","success");
    document.getElementById("comprovante").checked = true;
  }
  else{
    document.getElementById("comprovante").checked = false;
  }
}

function TelaInscricao(){
    let saldo = document.getElementById("saldo").getAttribute('data-valor');
    let tem_atv = false;

    let itens = document.querySelectorAll('.item_carrinho');
    if(undefined != itens && null != itens && itens.length > 0){
      tem_atv = true;
    } 
    

    if(tem_atv ==true && (undefined != saldo && null != saldo && parseFloat(saldo) >=0)){
      window.location.href = "/Inscricao";
    }
    else{
      swal("Verifique se foi lançado o produto ou se o saldo não está negativo","","error");
      return false;
    }
}

function TrocaTipoCredencial(cod_categoria){
   let tipo_credencial = document.getElementById("tipo_credencial").value;
   let selectElement = document.getElementById("tipo_credencial");
   let vl_anterior;
   for (let option of selectElement.options) {
          // Verifica se o option tem o atributo data-escolhido="true"
          if (option.getAttribute('data-escolhido') === 'true') {
              // Se tiver, armazena o valor desse option na variável
              vl_anterior = option.value;
              break; // Interrompe o loop após encontrar o primeiro
          }
   }
       if(undefined != cod_categoria && null != cod_categoria &&  undefined != tipo_credencial && null != tipo_credencial &&  undefined != vl_anterior && null != vl_anterior  && sessionStorage.getItem('token') != "" && sessionStorage.getItem('cod_inscricao') != "" && sessionStorage.getItem('cod_empresa') != "" && undefined != sessionStorage.getItem('cod_inscricao')  &&  undefined != sessionStorage.getItem('cod_empresa') && null != sessionStorage.getItem('cod_inscricao')  &&  null != sessionStorage.getItem('cod_empresa') && sessionStorage.getItem('cod_usser') != "" && sessionStorage.getItem('nome_usser') != ""){
 
              if(sessionStorage.getItem('md') == 'dev'){
                console.log('chamada processaStatus:',API_BASE+'processaStatus.php?cod_categoria='+cod_categoria+'&cod_inscricao='+sessionStorage.getItem('cod_inscricao')+'&cod_empresa='+sessionStorage.getItem('cod_empresa')+'&access_token='+sessionStorage.getItem('token')+"&id_user="+sessionStorage.getItem('nome_usser')+"&cod_usser="+sessionStorage.getItem('cod_usser')+"&cod_status_cred="+tipo_credencial);
              }
              fetch(API_BASE+'processaStatus.php?cod_categoria='+cod_categoria+'&cod_inscricao='+sessionStorage.getItem('cod_inscricao')+'&cod_empresa='+sessionStorage.getItem('cod_empresa')+'&access_token='+sessionStorage.getItem('token')+"&id_user="+sessionStorage.getItem('nome_usser')+"&cod_usser="+sessionStorage.getItem('cod_usser')+"&cod_status_cred="+tipo_credencial)
                    .then((response) => response.json())
                    .then((resultado) => {
                        if(sessionStorage.getItem('md') == 'dev'){
                            console.log('Resposta processaStatus:', resultado);
                        }
                        if(resultado.cod_inscricao != ""){
                          swal("Tipo de Credencial atualizado com sucesso","","success");
                        }
                        else{
                          alert("00 - Não foi possível efetuar a troca do tipo de credencial, recarregue a página e tente novamente.");
                          selectElement.value = vl_anterior;
                          }
                      })
                      .catch((error) => {
                          console.error(error);
                          alert("01 - Não foi possível efetuar a troca do tipo de credencial, recarregue a página e tente novamente.");
                          selectElement.value = vl_anterior;
                          
              });
       }
       else{
         /*console.log('cod_categoria:',cod_categoria);
         console.log('tipo_credencial:',tipo_credencial);
         console.log('vl_anterior:',vl_anterior);
         console.log('token:',sessionStorage.getItem('token'));
         console.log('cod_inscricao:',sessionStorage.getItem('cod_inscricao'));*/

         alert("02 - Não foi possível efetuar a troca do tipo de credencial, recarregue a página e tente novamente.");
         selectElement.value = vl_anterior;
         }
 
       
       
}

function AbreUrl(url){
  window.open(url);
  window.location.href="/";
}

export default {
    validaInscricao : (caminho) => validaInscricao(caminho),
    AbreModal : (dados) => AbreModal(dados),
    AbreLogin: () => AbreLogin(),
    IsEmail: (email) => IsEmail(email),
    isValidCPF: (chave, obrig) => isValidCPF(chave, obrig),
    isValidCNPJ: (chave, obrig) => isValidCNPJ(chave, obrig),
    EnviarCadastro: (campos, cod_inscricao, cod_empresa, cod_usser, caminho) => EnviarCadastro(campos, cod_inscricao, cod_empresa, cod_usser, caminho),
    FormataData : (tipo, valor) => FormataData(tipo, valor),
    FiltraCampos:() => FiltraCampos(),
    FModalPagamento:(saldo_devedor,forma_pg) => FModalPagamento(saldo_devedor,forma_pg),
    EmContrucao:() => EmContrucao(),
    validaLogin:() => validaLogin(),
    validaToken:() => validaToken(),
    AbreModalProdutos:(dados) => AbreModalProdutos(dados),
    CalculaCarrinho:() => CalculaCarrinho(),
    RemoveItem:(campo) => RemoveItem(campo),
    CalculaTotais:() => CalculaTotais(),
    Sair:() => Sair(),
    VerificaLancamentos:() => VerificaLancamentos(),
    RetiraExcluir:() => RetiraExcluir(),
    LimpaCarrinho:() => LimpaCarrinho(),
    ValidaSessao:(pg) => ValidaSessao(pg),
    AddAtividade:(atv,tipo) => AddAtividade(atv,tipo),
    CampoReferencia:(campo , id_referencia) => CampoReferencia(campo , id_referencia),
    buscaProdutos:(cod_categoria) => buscaProdutos(cod_categoria),
    ProdutosAnteriores:(prods,pg) =>ProdutosAnteriores(prods,pg),
    AvisaCategoria:() => AvisaCategoria(),
    TelaInscricao:() => TelaInscricao(),
    TrocaTipoCredencial:(cod_categoria) => TrocaTipoCredencial(cod_categoria),
    AbreUrl:(url) => AbreUrl(url)
}