import { useEffect, useState, React, useRef, useCallback, useMemo  } from "react";
import InputMask from "react-input-mask";
import Helper from "Helper";
import swal from "sweetalert";

function createMarkup(conteudo) {
    return {__html: conteudo};
  }
  
function setInjetaHtml(id,conteudo){
return <div dangerouslySetInnerHTML={createMarkup(conteudo)} />;
}

let i = -1;
let campo_html = "texto";
let mascara_html = "";
let campo_nome = "";
let campo_id = "";
let campos_1 = [];
let combo;


function Campos(item){
   
    const refs = useRef({});
    i++;
    item = item.item;

    if(item.tipo == "CPF"){
        if(undefined != sessionStorage.getItem('cpf') && null != sessionStorage.getItem('cpf') && Helper.isValidCPF(sessionStorage.getItem('cpf'), 'S')){
            item.valor =  sessionStorage.getItem('cpf');
            item.block = true;
        }
    }
    if(item.tipo == "EMAIL"){
        if(undefined != sessionStorage.getItem('email') && null != sessionStorage.getItem('email') && Helper.IsEmail(sessionStorage.getItem('email'))){
            item.valor =  sessionStorage.getItem('email');
            item.block = true;
        }
    }

    const fields = [item];
    campos_1 = [];
    campo_nome = "";
    campo_id = "";
    campo_nome = "campo"+item.cod_campo;
    campo_id = "campo"+item.cod_campo;   




  const initialState = useMemo(() => {
    return fields.reduce((acc, field) => {
        
      // Adiciona a lógica para inicializar o campo select
      if (field.tipo === 'COMBO') {
        const checkedOption = field.options.find(opcao => opcao.check === true || opcao.check === 'true');
        acc["campo" + field.cod_campo] = checkedOption ? checkedOption.value : '';
      } else {
        acc["campo" + field.cod_campo] = field.valor || (field.type === 'checkbox' ? false : '');
      }
      return acc;
    }, {});
  }, [fields]);

  const [formData, setFormData] = useState(initialState);
    
    // Função para lidar com mudanças nos campos do formulário
  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
        setFormData((prevData) => ({
        ...prevData,
        [id]: type === 'checkbox' ? checked : value,
        }));
         // Marcar o formulário como modificado
         sessionStorage.setItem('modificado',true);
         //console.log('md:',sessionStorage.getItem('modificado'));
    };


    const [options, setOptions] = useState(item.options);


    // Encontrar a opção que está marcada inicialmente
    const initialCheckedOption = options.find(option => option.check)?.value || '';

    const [selectedOption, setSelectedOption] = useState(initialCheckedOption);
   


    const handleRadioChange = (value) => {
    setSelectedOption(value);
    setOptions(options.map(option => ({
        ...option,
        check: option.value === value
    })));
    // Marcar o formulário como modificado
        sessionStorage.setItem('modificado',true);
        //console.log('md2:',sessionStorage.getItem('modificado'));
    };


   // Função para buscar o CEP
    const fetchCep = async (cep) => {
        try {
        const response = await fetch(cep);
        if (!response.ok) {
            throw new Error('Erro ao buscar o CEP');
        }
        const data = await response.json();
        return data;
        } catch (error) {
        console.error(error);
        }
    };

    const BuscaCep = async (e, codCampo, url) => {

        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 7000);
        let campo_f ="";
        const { value } = e.target;
        const formattedCep = value.replace(/\D/g, ''); // Remove caracteres não numéricos
        let pg = url+formattedCep+"&access_token="+sessionStorage.getItem('token');
        if (formattedCep.length === 8 || formattedCep.length === 14) {
          const data = await fetchCep(pg, {signal: controller.signal });
          if (data) {
            var dados = data.dados;
            if("dev" ==  sessionStorage.getItem('md') || "DEV" ==  sessionStorage.getItem('md')){
                console.log(dados);
            }
            if(undefined != dados && null != dados && dados.length>0){
                        dados.forEach(item => {
                            campo_f = "campo"+item.cod_campo;
                            if(document.getElementById(campo_f)){
                                document.getElementById(campo_f).value = item.valor;
                                document.getElementById(campo_f).setAttribute('value',item.valor);
                                if(item.block || item.block == 'true'){
                                    document.getElementById(campo_f).disabled = true;
                                    document.getElementById(campo_f).setAttribute('disabled',true);
                                }
                            } 

                        });
                    }
           
          }
        }
       
    };

    
    
   
    if(item.tipo == "CPF" || item.tipo == "CNPJ" || item.tipo == "CELULAR_BR" || item.tipo == 'DATA' || item.tipo == 'DATA_NASC' || item.tipo == 'CEP'){
        campo_html = "tel";
        if(item.tipo == "CPF"){
            mascara_html = "999.999.999-99";
            if(undefined != sessionStorage.getItem('cpf') && null != sessionStorage.getItem('cpf') && Helper.isValidCPF(sessionStorage.getItem('cpf'), 'S')){
                item.valor =  sessionStorage.getItem('cpf');
            }
             
        }
        else if(item.tipo == "CNPJ"){
            mascara_html = "99.999.999/9999-99";
        }
        else if(item.tipo == "CELULAR_BR"){
            mascara_html = "(99)99999-9999";
        }
        else if(item.tipo == 'DATA' || item.tipo == 'DATA_NASC'){
            mascara_html = "99/99/9999";   
            item.valor = Helper.FormataData('BR', item.valor.substr(0, 10));
         

        }else if(item.tipo == 'CEP'){
            mascara_html = "99999-999";
        }
        else{
            mascara_html = "";
        }

    }
    else if(item.tipo == "EMAIL"){
        campo_html = "email";
        mascara_html = "";
        if(undefined != sessionStorage.getItem('email') && null != sessionStorage.getItem('email') && Helper.IsEmail(sessionStorage.getItem('email'))){
            item.valor =  sessionStorage.getItem('email');
        }
    }
    else if(item.tipo == "NUMERICO"){
        campo_html = "tel";
        mascara_html = "";
    }
    else if(item.tipo == "ALFANUMERICO"){
        campo_html = "texto";
        mascara_html = "";
    }
    else if(item.tipo == "RADIO" || item.tipo == "CHECKBOX"){
            
        if(item.tipo == "CHECKBOX"){
            campo_html = "checkbox";    
        }
        else{
            campo_html = "radio";
        }
        mascara_html = "";
    }
    else if(item.tipo == "TERMO"){
        campo_html = "checkbox";
        mascara_html = "";
    }
    else if(item.tipo == "COMBO"){
        mascara_html = "";
    }
    else{
        campo_html = "texto";
        mascara_html = "";
    }
    ;


    if(item.tipo == "COMBO"){
        campo_html = "";
        let combo = item.options;
        campos_1.push(    
        <>
            <label htmlFor={item.cod_campo} className={`mb-2 font-medium `+(item.label.length > 28 ?'text-sm':'')}>
              {item.label} <span className="tx_obrigatorio">{item.obrigatorio == true ?"*":""}</span>
            </label>
            <select 
                className="p-2 border border-gray-300 rounded-md campos"
                name={campo_nome}
                id={campo_id} 
                autoComplete="off"
                data-min = {item.minlenght}
                data-max = {item.maxlenght}
                data_obrigatorio={(item.obrigatorio ? 'S': 'N' )}
                data-validacao = {item.validacao} 
                data-tipo = {item.tipo} 
                maxLength={item.maxlenght}
                data-campo = {item.campo}
                data-chamada={false}
                title = {item.label}
                data-cod_campo={item.cod_campo}
                tabindex={item.ordem} 
                value={formData[campo_id]}
                onChange={handleChange}
                data_sintetico={(item.sintetico ? 'S': 'N' )}
                data_analitico={(item.analitico ? 'S': 'N' )}
                disabled={item.block}
                >
               
                    <option value="">Selecione uma opção</option>    
                    {combo.map((opcoes, i) => (
                              <option value={opcoes.value}  key={`op${i}`}>{opcoes.label}</option>
                            ))}
            </select>
           
        </>
        );  
    }else if(item.tipo == "TERMO"){
        campos_1.push(    
            <>
                <table className="table-auto border-collapse w-full">
                    <tbody>
                        <tr className="bg-gray-200 text-black">
                            <td className="border px-4 py-2"><b><span id={`RC`+campo_nome} title={item.label}>{item.label}</span></b> <span className="tx_obrigatorio">{item.obrigatorio == true ?"*":""}</span></td>
                        </tr>
                        <tr>
                            <td className="border px-4 py-2">
                                <div  id={`TERMO${campo_id}`}>
                                    {                             
                                    setInjetaHtml(`TERMO${campo_id}`, item.placeholder)
                                    }
                               </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-2 border border-gray-300 rounded-md">
                            <input type="checkbox"  className="campos" 
                                name={campo_nome}
                                id={campo_id} 
                                autoComplete="off"
                                data-min = {item.minlenght}
                                data-max = {item.maxlenght}
                                data_obrigatorio={(item.obrigatorio ? 'S': 'N' )}
                                data-validacao = {item.validacao} 
                                data-tipo = {item.tipo} 
                                maxLength={item.maxlenght}
                                data-campo = {item.campo}
                                data-chamada={false}
                                title = {item.label}
                                data-cod_campo={item.cod_campo}
                                tabindex={item.ordem}
                                value={item.options[0] !==""? item.options[0].value:null}
                                defaultChecked={item.options[0].check}
                                data_sintetico={(item.sintetico ? 'S': 'N' )}
                                data_analitico={(item.analitico ? 'S': 'N' )}
                                onChange={handleChange}
                                 /> &nbsp;   
                            Eu aceito
                          
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>
         )
    }else if(item.tipo === "RADIO"){
        combo = item.options;
        campos_1.push(    
            <>
                <label for={item.cod_campo} className={`mb-2 font-medium `+(item.label.length > 28 ?'text-sm':'')}><span id={`RC`+campo_nome} title={item.label}>{item.label}</span> <span className="tx_obrigatorio">{item.obrigatorio == true ?"*":""}</span></label>
                <table className="table-auto w-full p-2">
                    <tbody>
                            {combo.map((opcoes, i) => (
                                <tr className="radio_check">
                                    <td className="px-4 py-2">
                                        <input 
                                        type={campo_html} 
                                        name={campo_nome}
                                        id={campo_id+"_"+i} 
                                        className="campos" 
                                        autoComplete="off"
                                        data-min = {item.minlenght}
                                        data-max = {item.maxlenght}
                                        data_obrigatorio={(item.obrigatorio ? 'S': 'N' )}
                                        data-validacao = {item.validacao} 
                                        data-tipo = {item.tipo} 
                                        maxLength={item.maxlenght}
                                        data-campo = {item.campo}
                                        data-chamada={false}
                                        title = {opcoes.label}
                                        data-cod_campo={item.cod_campo}
                                        tabindex={item.ordem}
                                        value={opcoes.value}
                                        checked={selectedOption === opcoes.value}
                                        onChange={() => handleRadioChange(opcoes.value)}
                                        data_sintetico={(item.sintetico ? 'S': 'N' )}
                                        data_analitico={(item.analitico ? 'S': 'N' )}
                                        />
                                   
                                    </td>
                                    <td>{opcoes.label}</td>
                                </tr>
                            ))}
                       
                       
                    </tbody>
                </table>
            </>
         )

    }else if(item.tipo === "CHECKBOX"){
        combo = item.options;
        campos_1.push(    
            <>
                <label for={item.cod_campo} className={`mb-2 font-medium `+(item.label.length > 28 ?'text-sm':'')}><span id={`RC`+campo_nome} title={item.label}>{item.label} <span className="tx_obrigatorio">{item.obrigatorio == true ?"*":""}</span></span></label>
                <table className="table-auto w-full p-2">
                    <tbody>
                            {combo.map((opcoes, i) => (
                                <tr className="radio_check">
                                    <td className="px-4 py-2">
                                        <input 
                                        type={campo_html} 
                                        name={campo_nome}
                                        id={campo_id+"_"+i} 
                                        className="campos" 
                                        autoComplete="off"
                                        data-min = {item.minlenght}
                                        data-max = {item.maxlenght}
                                        data_obrigatorio={(item.obrigatorio ? 'S': 'N' )}
                                        data-validacao = {item.validacao} 
                                        data-tipo = {item.tipo} 
                                        maxLength={item.maxlenght}
                                        data-campo = {item.campo}
                                        data-chamada={false}
                                        title = {opcoes.label}
                                        data-cod_campo={item.cod_campo}
                                        tabindex={item.ordem}
                                        value={opcoes.value}
                                        defaultChecked={opcoes.check}
                                        data_sintetico={(item.sintetico ? 'S': 'N' )}
                                        data_analitico={(item.analitico ? 'S': 'N' )}
                                        onChange={handleChange}
                                        />
                                   
                                    </td>
                                    <td>{opcoes.label}</td>
                                </tr>
                            ))}
                       
                       
                    </tbody>
                </table>
            </>
         )

    }else if(item.tipo == "CPF" || item.tipo == "CNPJ" || item.tipo == "CELULAR_BR" || item.tipo == 'DATA' || item.tipo == 'DATA_NASC' || item.tipo == 'CEP'){
       
        campos_1.push(    
            <>
                <label htmlFor={item.cod_campo} className={`mb-2 font-medium `+(item.label.length > 28 ?'text-sm':'')}>{item.label} <span className="tx_obrigatorio">{item.obrigatorio == true ?"*":""}</span></label>

                <InputMask 
                    mask={mascara_html} 
                    name={campo_nome}
                    id={campo_id}
                    placeholder={mascara_html}  
                    className="p-2 border border-gray-300 rounded-md campos" 
                    autoComplete="off"
                    data-min = {item.minlenght}
                    data-max = {item.maxlenght}
                    data_obrigatorio={(item.obrigatorio ? 'S': 'N' )}
                    data-validacao = {item.validacao} 
                    data-tipo = {item.tipo} 
                    data-campo = {item.campo}
                    data-chamada={false}
                    title = {item.label}
                    data-cod_campo={item.cod_campo}
                    tabindex={item.ordem} 
                    value={formData[campo_id]}
                    onChange={(e) => handleChange(e, campo_id)}
                    data_sintetico={(item.sintetico ? 'S': 'N' )}
                    data_analitico={(item.analitico ? 'S': 'N' )}
                    onBlur={(item.tipo === 'CEP' || item.tipo === 'CNPJ') ? (e) => BuscaCep(e, campo_id, item.validacao) : undefined}
                    ref={(el) => refs.current[campo_id] = el}
                    defaultValue={item.valor}
                    disabled={item.block}
                    />
 
            </>
          )
    }
    else if(item.tipo == "NOME_RESUMIDO"){
            campos_1.push(    
                <>
                <label htmlFor={item.cod_campo} className="mb-2 font-medium">
                {item.label} <span className="tx_obrigatorio">{item.obrigatorio == true ?"*":""}</span>
                </label>
                <input
                    type={campo_html}
                    name={campo_nome}
                    id={campo_id} 
                    autoComplete="off"
                    data-min = {item.minlenght}
                    data-max = {item.maxlenght}
                    data_obrigatorio={(item.obrigatorio ? 'S': 'N' )}
                    data-validacao = {item.validacao} 
                    data-tipo = {item.tipo} 
                    maxLength={item.maxlenght}
                    data-campo = {item.campo}
                    data-chamada={false}
                    title = {item.label}
                    data-cod_campo={item.cod_campo}
                    tabindex={item.ordem}
                    value={formData[campo_id].substring(0,item.maxlenght)}
                    onChange={handleChange}
                    className="p-2 border border-gray-300 rounded-md campos"
                    data_sintetico={(item.sintetico ? 'S': 'N' )}
                    data_analitico={(item.analitico ? 'S': 'N' )}
                    ref={(el) => refs.current[campo_id] = el}
                    disabled={item.block}
                    onFocus = {(ev) => {      
                        Helper.CampoReferencia("campo"+item.cod_campo, item.campo_referencia)
                      }} 
                    />
        
            
        </>
            );
    }else if(item.tipo == "MEMO"){
     campos_1.push(    
            <>
             <label htmlFor={item.cod_campo} className="mb-2 font-medium">
              {item.label} <span className="tx_obrigatorio">{item.obrigatorio == true ?"*":""}</span>
            </label>
            <textarea
                type={campo_html}
                name={campo_nome}
                id={campo_id} 
                autoComplete="off"
                data-min = {item.minlenght}
                data-max = {item.maxlenght}
                data_obrigatorio={(item.obrigatorio ? 'S': 'N' )}
                data-validacao = {item.validacao} 
                data-tipo = {item.tipo} 
                maxLength={item.maxlenght}
                data-campo = {item.campo}
                data-chamada={false}
                title = {item.label}
                data-cod_campo={item.cod_campo}
                tabindex={item.ordem}
                value={formData[campo_id].substring(0,item.maxlenght)}
                onChange={handleChange}
                className="p-2 border border-gray-300 rounded-md campos"
                data_sintetico={(item.sintetico ? 'S': 'N' )}
                data_analitico={(item.analitico ? 'S': 'N' )}
                ref={(el) => refs.current[campo_id] = el}
                disabled={item.block}
                />
      
           
      </>
        );
    
    }
    else{
        campos_1.push(    
            <>
             <label htmlFor={item.cod_campo} className="mb-2 font-medium">
              {item.label} <span className="tx_obrigatorio">{item.obrigatorio == true ?"*":""}</span>
            </label>
            <input
                type={campo_html}
                name={campo_nome}
                id={campo_id} 
                autoComplete="off"
                data-min = {item.minlenght}
                data-max = {item.maxlenght}
                data_obrigatorio={(item.obrigatorio ? 'S': 'N' )}
                data-validacao = {item.validacao} 
                data-tipo = {item.tipo} 
                maxLength={item.maxlenght}
                data-campo = {item.campo}
                data-chamada={false}
                title = {item.label}
                data-cod_campo={item.cod_campo}
                tabindex={item.ordem}
                value={formData[campo_id].substring(0,item.maxlenght)}
                onChange={handleChange}
                className="p-2 border border-gray-300 rounded-md campos"
                data_sintetico={(item.sintetico ? 'S': 'N' )}
                data_analitico={(item.analitico ? 'S': 'N' )}
                ref={(el) => refs.current[campo_id] = el}
                disabled={item.block}
                />
      
           
      </>
        );
    }


    
 return (

        campos_1
       
    )
   
        
      
}

export default Campos;