import Rodape from "Components/Rodape";
import Topo from "Components/Topo";
import InputMask from "react-input-mask";
import React, { useState } from 'react';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import validator from 'validator';
import { Navigate } from 'react-router-dom';
import { API_BASE } from "API";
import swal from "sweetalert";
import Helper from "Helper";


function Pesquisa(){
        const [isChecked, setIsChecked] = useState(sessionStorage.getItem('idioma') === '1');
        //const [isChecked, setIsChecked] = useState(false);
        const [cpfInput, setCpfInput] = useState('');
        const [emailInput, setEmailInput] = useState('');
        const [cpfValid, setCpfValid] = useState(false);
        const [emailValid, setEmailValid] = useState(false);
        const [paisValid, setPaisValid] = useState(false);
        let url="";
        const handleSelectChange = (event) => {
            setPaisValid(event.target.value);
        };

       
        
        const handleCpfChange = (event) => {
            const inputValue = event.target.value;
            setCpfInput(inputValue);
            setCpfValid(cpfValidator.isValid(inputValue));
        };
        
        const handleEmailChange = (event) => {
            const inputValue = event.target.value;
            setEmailInput(inputValue);
            setEmailValid(validator.isEmail(inputValue));
        };


        const verificaStatus = () => {
            setIsChecked(!isChecked);
        }

        
        const  verificaInscricao =  async (url,btn_id) =>{
            
            try {
                const response = await fetch(url);
                const text = await response.text(); // Obtenha a resposta como texto
                // Remove o BOM se presente
                const cleanText = text.replace(/^\uFEFF/, '');
                const data = JSON.parse(cleanText);
               
                     
                    //console.log('data:',data);
                if(data.status.duplicidade){
                   swal(data.status.msg,"","error");
                   document.getElementById("cpf").value="";
                   document.getElementById("email").value="";
                   if( document.getElementById(btn_id)){
                    document.getElementById(btn_id).textContent = "Buscar";
                   }
                   return false; 
                }
                else{
                     sessionStorage.setItem('cod_inscricao','');
                     sessionStorage.setItem('cod_empresa','');
                    if(data.cadastro && data.cadastro[0] && data.cadastro[0].destino == 'CREDENCIAL'){
                        //console.log('Inscricao');
                        if(data.cadastro && data.cadastro[0] && data.cadastro[0].cod_inscricao){
                            sessionStorage.setItem('cod_inscricao',data.cadastro[0].cod_inscricao);
                        }
                        if(data.cadastro && data.cadastro[0] && data.cadastro[0].cod_empresa){
                            sessionStorage.setItem("cod_empresa",data.cadastro[0].cod_empresa);
                        }
                        
                        window.location.href='/Inscricao';

                    }
                    else if(data.cadastro && data.cadastro[0] && data.cadastro[0].destino == 'VENDA'){
                        //console.log('Venda');
                        if(data.cadastro && data.cadastro[0] && data.cadastro[0].cod_inscricao){
                            sessionStorage.setItem('cod_inscricao',data.cadastro[0].cod_inscricao);
                        }
                        if(data.cadastro && data.cadastro[0] && data.cadastro[0].cod_empresa){
                            sessionStorage.setItem("cod_empresa",data.cadastro[0].cod_empresa);
                        }
                       window.location.href='/VendaRapida';
                    }
                    else{
                        //console.log('Cadastro');
                        if(data.cadastro && data.cadastro[0] && data.cadastro[0].cod_inscricao){
                            sessionStorage.setItem('cod_inscricao',data.cadastro[0].cod_inscricao);
                        }
                        window.location.href='/Cadastro';
                    }

                    
                   
                } 
                  
            }catch(error){
                    console.log(error);
                  }
              }
        

        const handleSubmit = (event) => {
              if(!Helper.validaToken()){
                swal("Token Expirado, acesse o link principal novamente","","error");
                return false;
              }
              if(!Helper.validaLogin()){
                swal("Login Expirado, acesse o link principal novamente","","error");
                return false;
              }
            event.preventDefault();
            // Realizar validação final
            let isCpfValid = cpfValidator.isValid(cpfInput);
            let isEmailValid = validator.isEmail(emailInput);
           
            
            if (isChecked) {
                if(isEmailValid && paisValid) {
                    document.getElementById("btn_buscar_estrangeiro").textContent = "Aguarde...";
                    sessionStorage.setItem('email',emailInput);
                    sessionStorage.setItem('pais',paisValid);
                    sessionStorage.setItem('cpf','');
                    url = API_BASE+"getCadastro.php?cpf=&email="+emailInput+"&pais="+paisValid+"&access_token="+sessionStorage.getItem("token")+"&id_user="+sessionStorage.getItem('nome_usser')+"&cod_usser="+sessionStorage.getItem('cod_usser');
                    verificaInscricao(url,'btn_buscar_estrangeiro');
                    
                    
                }
                else{
                    document.getElementById("btn_buscar_estrangeiro").textContent = "Buscar";
                    alert('Digite um e-mail válido e selecione o país');
                }

               
            }
            else{
                if(isCpfValid){
                    document.getElementById("btn_buscar_brasileiro").textContent = "Aguarde...";
                    sessionStorage.setItem('cpf',cpfInput.replace(/[^0-9]/g,''));
                    sessionStorage.setItem('email','');
                    sessionStorage.setItem('pais','');
                    url = API_BASE+"getCadastro.php?cpf="+cpfInput.replace(/[^0-9]/g,'')+"&email=&pais=&access_token="+sessionStorage.getItem("token")+"&id_user="+sessionStorage.getItem('nome_usser')+"&cod_usser="+sessionStorage.getItem('cod_usser');
                    verificaInscricao(url,'btn_buscar_brasileiro');
                }
                else{
                    document.getElementById("btn_buscar_brasileiro").textContent = "Buscar";
                    alert('Digite um CPF Válido');
                }
            }
            
            
        };
      
        let lista_paises = sessionStorage.getItem('lista_paises');
        let lista_p = [];
        if (undefined != lista_paises && null != lista_paises){
            JSON.parse(lista_paises).map((item) => {
                
                lista_p.push(
                    <option 
                      value={item.id_pais} 
                    >
                      {item.pais}
                    </option>
                  );
            })
        }

          
          setTimeout(() => {
            const loginInput = document.getElementById('cpf');
            if (loginInput && Helper.validaLogin()) {
                loginInput.focus();
            }
            const idioma = sessionStorage.getItem("idioma");
            if (idioma == "1") {
                const loginInput2 = document.getElementById('email');
                if (loginInput2 && Helper.validaLogin()) {
                    loginInput2.focus();
                }

                setPaisValid('BR'); // Aqui definimos 'BR' como o país selecionado se o idioma for 1
            }
          }, 0);

         
          
    return (
        <>
        <Topo texto="Pesquisa" cor_fundo="bg-sky-500" cor_texto="text-white"></Topo>
        <form onSubmit={handleSubmit}>
        <div className="flex flex-col justify-center items-center h-80">
            <div style={{ display: isChecked ? 'none' : 'block' }}>
                <div className="flex justify-center items-center text-sky-500 font-bold text-xl p-4 mb-4">Digite o CPF</div>
                    <table className="m-4 bg-sky-500 h-14 w-80">
                    <tr>
                        <td>
                        <InputMask 
                            mask="999.999.999-99" 
                            id="cpf" 
                            type="tel" 
                            placeholder="000.000.000-00" 
                            className={!cpfValid ? 'w-52 h-9 ml-3 text-center border border-red-500':'w-52 h-9 ml-3 text-center'}  
                            value={cpfInput}
                            onChange={handleCpfChange}
                            autoComplete="off"
                            />
                          
                        </td>
                        <td><button className="border w-20 h-9 text-white mr-2" id="btn_buscar_brasileiro">Buscar</button></td>
                    </tr>
                    </table>
                    
                    </div>
                    <div style={{ display: isChecked ? 'block' : 'none' }}>
                    <table className="m-4 h-24 p-4">
                    <tr className="font-bold text-sky-500">
                        <td className="text-center">E-mail</td>
                        <td className="text-center">País</td>
                        <td></td>
                    </tr>    
                    <tr className="bg-sky-500">
                        <td>
                        <input 
                            type="text"
                            id="email" 
                            className={!emailValid ? 'w-72 h-9 ml-3 text-center border border-red-500' : 'w-72 h-9 ml-3 text-center'} 
                            value={emailInput}
                            onChange={handleEmailChange}
                            autoComplete="off"
                            />
                            
                        </td>
                        <td>
                        <select 
                            id="pais" 
                            className="w-52 h-9 ml-3 text-center"  
                            value={paisValid} onChange={handleSelectChange}
                            >
                            <option value="">Selecione um país</option>
                            
                            {lista_p}

                        </select>        

                        </td>
                        <td className="w-28"><button className="border w-20 h-9 text-white mr-2 ml-5" id="btn_buscar_estrangeiro">Buscar</button></td>
                    </tr>
                    </table>
                    </div>
                    
                    <div class="flex  items-center justify-center p-4">
                        <input type="checkbox" id="check_tipo" class="form-checkbox h-5 w-5 text-blue-500" checked={isChecked}  onChange={verificaStatus} />
                        <span class="ml-2 text-gray-700">Estrangeiro</span>
                    </div>
          

            
        </div>
        </form>
        <Rodape passo="1" />
        </>
        
    )
}

export default Pesquisa;