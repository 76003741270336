import Rodape from "Components/Rodape";
import Topo from "Components/Topo";
//import jsonData from '../../json/cadastro.json';
import { useEffect, useState, React, useRef, useCallback  } from "react";
import Webcam from "react-webcam";
import Helper from "Helper";
import Campos from "Components/Campos";
import Camera from "Components/Camera";
import swal from "sweetalert";
import { API_BASE } from "API";

function AbreCamera(){
  const wrapper = document.createElement('div');
  wrapper.innerHTML = <Camera />;
  swal({
    title: "Faça a captura da foto",
    content: wrapper,
    className: "alert_title", 
    icon: "error"
  });
}

function preencheLink(){
  let url_arquivo = sessionStorage.getItem("url_arquivo");
  let nomeImagem = sessionStorage.getItem("nomeImagem");
  if(document.getElementById("img_foto") && url_arquivo !=""  && nomeImagem !=""){
      document.getElementById("img_foto").setAttribute("href",url_arquivo);
      document.getElementById("img_foto").setAttribute("data-arquivo",nomeImagem);
      sessionStorage.setItem("foto",nomeImagem);
  }
  return "";
}

function Cadastro(){
const url = API_BASE+"getCadastro.php?cpf="+sessionStorage.getItem("cpf")+"&email="+sessionStorage.getItem("email")+"&pais="+sessionStorage.getItem("pais")+"&access_token="+sessionStorage.getItem("token")+"&id_user="+sessionStorage.getItem('nome_usser')+"&cod_usser="+sessionStorage.getItem('cod_usser');
const [data, setData] = useState(null);
const [processatipos, setProcessedData] = useState([]);
const [processahistorio, setProcesseHistorico] = useState([]);
sessionStorage.setItem('modificado',false);
let url_arquivo ;
let nomeImagem;

if(Helper.ValidaSessao('') == false || (undefined == sessionStorage.getItem('cpf') || null == sessionStorage.getItem('cpf') || "" == sessionStorage.getItem('cpf')) && (undefined == sessionStorage.getItem('email') || null == sessionStorage.getItem('email') || "" == sessionStorage.getItem('email'))){
  window.location.href="/";
}

  useEffect(() => {
    // Função para buscar os dados do JSON
    const fetchData = async () => {
      try {
   

        const response = await fetch(url);
        const text = await response.text(); // Obtenha a resposta como texto
        // Remove o BOM se presente
        const cleanText = text.replace(/^\uFEFF/, '');
        const jsonData = JSON.parse(cleanText);
        const fields = jsonData.campos;
        const dados = jsonData;

        if(dados.status.duplicidade){
          swal(dados.status.msg,"","error");
          window.location.href="/";
        }
      

        let tipos = [];
        let historico_dados = dados.historico;
        let foto_a = dados.foto;
       

       
        if(undefined != foto_a && null != foto_a && undefined != foto_a[0].url_existente && null != foto_a[0].url_existente && foto_a[0].url_existente !="https://pvista.proevento.com.br/rsvp/webcam/imgphoto/"){
          url_arquivo = foto_a[0].url_existente;
          nomeImagem = url_arquivo.split('/').pop();
          sessionStorage.setItem("url_arquivo",url_arquivo);
          sessionStorage.setItem("nomeImagem",nomeImagem);
          setTimeout(() => preencheLink(), 500);
         
        }
        else{
         
          sessionStorage.setItem("url_arquivo",'');
          sessionStorage.setItem("nomeImagem",'');
        }

        dados.tipo_cred.map(item => {(
        tipos.push(<option value={item.cod_status_cred} selected={item.escolhido == 'S' ? true:false} style={{ backgroundColor: item.cor, color: '#000' }} title={item.nome}>{item.nome}</option>)    
        )});
        setProcessedData(tipos);
        setData(jsonData);
        setProcesseHistorico(historico_dados);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    fetchData();

   

  }, []);

  if("dev" ==  sessionStorage.getItem('md') || "DEV" ==  sessionStorage.getItem('md')){
      console.log('Chamada GetCadastro:',url);
      console.log('data:',data);
  }
  

    return (
        data ? (
          (data.cadastro.length > 0 && undefined != data.cadastro[0].cod_inscricao && null != data.cadastro[0].cod_inscricao && data.cadastro[0].cod_inscricao !="" ? sessionStorage.setItem('cod_inscricao',data.cadastro[0].cod_inscricao):''),
        <>
        <Topo texto="Cadastro" cor_fundo="fundoCadastro" cor_texto="text-white"></Topo>
        <div className="flex">
        <div className="w-1/3 p-4 remove_celular"></div>
        <form className="max-w-4xl mx-auto p-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {data.campos.map((item) => ( 
                <div key={item.cod_campo} data-c={item.cod_campo} className={`flex flex-col divs ${(item.sintetico?'sintetico':'')} ${(item.analitico?'analitico':'')} ${(item.sintetico == false?' esconde_primeiro':'')}`} >
                <Campos item={item}/>
                </div>
                ))}
            </div>
     
         </form>
          

            <div className="w-1/3 p-4 flex flex-col items-center cad_celular">
                        <div className="w-full py-2 px-4 rounded mb-3 text-center">
                            
                                <table className="table-auto w-full left_celular">
                                    <tbody>
                                        <tr className="fundoCadastro">
                                            <td className="border px-4 py-2 text-white font-bold"><b>CADASTRO</b></td>
                                        </tr>
                                        <tr>
                                            <td className="border px-4 py-2 text-xl">
                                                <input type="hidden" name="cod_inscricao" id="cod_inscricao" value={(data.cadastro.length > 0 && undefined != data.cadastro[0].cod_inscricao && null != data.cadastro[0].cod_inscricao && data.cadastro[0].cod_inscricao !="" ? data.cadastro[0].cod_inscricao:'')} />
                                                <input type="hidden" name="cod_empresa" id="cod_empresa" value={(data.cadastro.length > 0 && undefined != data.cadastro[0].cod_empresa && null != data.cadastro[0].cod_empresa && data.cadastro[0].cod_empresa !="" ? data.cadastro[0].cod_empresa:'')} />
                                            <div id="tx_cod_inscricao">{(data.cadastro.length > 0 && undefined != data.cadastro[0].cod_empresa && null != data.cadastro[0].cod_empresa && data.cadastro[0].cod_empresa !="" ? data.cadastro[0].cod_empresa:'')}</div>
                                            </td>
                                        </tr>
                                    
                                    </tbody>
                                </table>

                        </div>
                <Camera />
 
                <input type='hidden' id="modo_campo" value="sintetico" />
                <button className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-3" onClick={(ev) => {Helper.AbreModal(processahistorio)}}>Histórico</button>
                <button className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-3" onClick={(ev) => {Helper.FiltraCampos()}} id="btn_modo_campo"><span id="tx_modo_campo">Analítico</span></button>
                <span className=" text-blue-700 font-bold py-2 px-4">Tipo Credencial</span>
                
                

                <select 
                className="w-full text-blue-500 font-bold py-2 px-4 rounded border text-center"
                name="tipo_credencial" 
                id="tipo_credencial" 
                data_obrigatorio="S" 
                data-validacao = ""
                data-tipo = "tipo_credencial"
                data-campo = "tipo_credencial"
                title="Tipo Credencial"
                data-cod_campo="tipo_credencial">
                {(processatipos.length >1 ? <option value="">Selecione</option>:'')}
                {processatipos}
                </select>
                <br /><br />
                <div id="loading"></div><button className="w-full bg-black text-white font-bold py-2 px-4 rounded mb-3" id="avancar_cadastro" onClick={()=>{Helper.validaInscricao(3)}}>Avançar</button>

            </div>
            <div className="w-1/3 p-4 remove_celular"></div>
        </div>   
        <Rodape passo="2"/>
       
        </>
        
          ) : (
            <p>Carregando...</p>
          ) 
         
        )

     

    
     
}

export default Cadastro;