import Rodape from "Components/Rodape";
import Topo from "Components/Topo";
import Helper from "Helper";
import historico from "../../img/historico.png";
import ModalPagamento from "Components/ModalPagamento"; 
import { useEffect, useState } from "react";
import { API_BASE } from "API";
import swal from "sweetalert";

//import jsonData from '../../json/venda.json';


function Inscricao(){
    const url = API_BASE+"getCredencial.php?cod_inscricao="+sessionStorage.getItem("cod_inscricao")+"&access_token="+sessionStorage.getItem("token")+"&id_user="+sessionStorage.getItem('nome_usser')+"&cod_usser="+sessionStorage.getItem('cod_usser');
    if(sessionStorage.getItem('md') == 'dev'){
        console.log("Chamada getCredencial:", url);
    }
    sessionStorage.setItem("saldo",0);
    sessionStorage.setItem("lancamento","");
    Helper.LimpaCarrinho();
    


    
      // Lendo endpoints
      

      const [dados, setData] = useState(null);
      const [processatipos, setProcessedData] = useState([]);
      const [processahistorio, setProcesseHistorico] = useState([]);
      let tipos = [];
      let historico_dados;
      useEffect(() => {
        // Função para buscar os dados do JSON
        const fetchData = async () => {
          try {
            tipos = [];
            const response = await fetch(url);
            const text = await response.text(); // Obtenha a resposta como texto
            // Remove o BOM se presente
            const cleanText = text.replace(/^\uFEFF/, '');
            const jsonData = JSON.parse(cleanText);
            if("dev" ==  sessionStorage.getItem('md') || "DEV" ==  sessionStorage.getItem('md')){
                console.log('retorno Credencial:',jsonData);
            }
            const fields = jsonData.campos;
            let historico_dados = (jsonData && jsonData && jsonData.historico && jsonData.historico.length > 0 ? jsonData.historico : []);
            setData(jsonData);
            setProcesseHistorico(historico_dados);
           
            jsonData.tipo_cred.map(item => {(
                tipos.push(<option value={item.cod_status_cred} data-escolhido={item.escolhido == 'S' ? true:false} selected={item.escolhido == 'S' ? true:false} style={{ backgroundColor: item.cor, color: '#000' }} title={item.nome}>{item.nome}</option>)    
                )});
                
            setProcessedData(tipos);
          } catch (error) {
            console.error('Erro ao buscar dados:', error);
          }
        };
    
        fetchData();
      }, []);

      const [htmlContent, setHtmlContent] = useState('');

      useEffect(() => {
          if (dados && dados.cadastro && dados.cadastro[0] && dados.cadastro[0].produtos) {
          // Chama a função ProdutosAnteriores e armazena o HTML gerado
          const html = Helper.ProdutosAnteriores(dados.cadastro[0].produtos,4);
          setHtmlContent(html);
          }
      }, [dados]);

  useEffect(() => {
    const buttonsEx = document.querySelectorAll('.excluir');
    // Limpa os event listeners quando o componente é desmontado
    return () => {
      buttonsEx.forEach(button => {
        button.style.display="none";
      });
    };
  }, [htmlContent]);

      if(Helper.ValidaSessao('4') === false){
        return <div>Aguardando sua ação...</div>;
      }

     
     
      
        if (!dados) {
            return <div>Carregando...</div>;
        }
        else{
                if(undefined == sessionStorage.getItem("cod_inscricao") || null == sessionStorage.getItem("cod_inscricao") || "" == sessionStorage.getItem("cod_inscricao")){
                    sessionStorage.setItem("cod_inscricao",dados.cadastro[0].cod_inscricao);
                }
                if(undefined == sessionStorage.getItem("cod_empresa") || null == sessionStorage.getItem("cod_empresa") || "" == sessionStorage.getItem("cod_empresa")){
                         sessionStorage.setItem("cod_empresa",dados.cadastro[0].cod_empresa);
                }
        }

        if(undefined != dados.saldo[0].total_inscricao.replace("-","") && null != dados.saldo[0].total_inscricao.replace("-","") && dados.saldo[0].total_inscricao.replace("-","") > 0){
            sessionStorage.setItem("total_inscricao",(parseFloat(dados.saldo[0].total_inscricao)));
        }
        else{
            sessionStorage.setItem("total_inscricao",0);
        }

        if(undefined != dados.saldo[0].total_pago.replace("-","") && null != dados.saldo[0].total_pago.replace("-","") && dados.saldo[0].total_pago.replace("-","") > 0){
            sessionStorage.setItem("total_pago",(parseFloat(dados.saldo[0].total_pago)));
        }
        else{
            sessionStorage.setItem("total_pago",0);
        }

        if(undefined != dados.saldo[0].saldo.replace("-","") && null != dados.saldo[0].saldo.replace("-","") && dados.saldo[0].saldo.replace("-","") > 0){
            sessionStorage.setItem("saldo",(parseFloat(dados.saldo[0].saldo)));
        }
        else{
            sessionStorage.setItem("saldo",0);
        }

       
        
    return (
        <>
        <Topo  texto="Inscrição" cor_fundo="bg-green-600" cor_texto="text-white"></Topo>
        <div className="flex w_atv_celular">
        <div className="w-1/3 p-4 remove_celular"></div>
        <form className="max-w-4xl mx-auto p-1">
        <div class="container mx-auto">
        <div class="flex flex-col items-left justify-center  h-[50px] h30_celular">
                <span class="w-full text-left">Nome : {dados.cadastro[0].nome}</span>
                
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
           
            {(dados.cadastro[0].cpf !="" ?
            <div class="flex flex-col items-left justify-center  h-[50px] h30_celular">
                <span class="w-full text-left">CPF</span>
                {dados.cadastro[0].cpf}
            </div> : '')}

            {(dados.cadastro[0].cpf =="" && dados.cadastro[0].email !="" ?
            <div class="flex flex-col items-left justify-center  h-[50px] h30_celular">
                <span class="w-full text-left">E-MAIL</span>
                {dados.cadastro[0].email}
            </div> : '')}
            
            <div class="flex flex-col items-center justify-center  h-[50px] h30_celular">
                <span class="w-full text-center">Atividade</span>
                <span class={`w-full text-center ${(dados.cadastro[0].atividade && dados.cadastro[0].atividade.length > 17) ? 'f14' : ''} f12_celular `}>{(dados.cadastro[0].atividade && null != dados.cadastro[0].atividade)?dados.cadastro[0].atividade.substring(0,25):''}</span>
            </div>
            <div class="flex flex-col items-center justify-center  h-[50px] h30_celular">
                <span class="w-full text-center">Categoria</span>
                <select id="categoria" className="border w-full h30 text-center" onChange={()=> {Helper.AvisaCategoria();}}>
                                {dados.cadastro[0].categorias.map((cad) => (
                                    <option value={cad.cod_categoria} selected={(cad.escolhido=='S'?true:false)} data-comprovante={(cad.comprovante?'S':'N')} data-texto={cad.categoria}>{cad.categoria}</option>
                                ))}
                </select>
            </div>
            <div class="flex flex-col items-center justify-center  h-[50px] h30_celular">
                <span class="w-full text-center">Comprovante</span>
                <input type="checkbox" name="comprovante" id="comprovante" value=""  className="p-2 border"/>
            </div>

            
      
            <div className="espaco_atv2"></div>
        </div>
    </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4"> 
                
            <div className="caixa_atividades" id="caixa_atividades" dangerouslySetInnerHTML={{ __html: htmlContent }}>
               

            </div>
            <div className="espaco_atv"></div>
            
            
           

            
             
            
        
            
            </div>   
            <div class="container mx-auto w-full">
       
       <div class="flex w_atv_celular tx10 tmTotais">
           <div  class="w-1/3 h-8 flex items-center justify-center border" title="Valor Total">TOTAL</div>
           <div class="w-1/3 h-8 flex items-center justify-center border" title="Valor Pago"><span  title="Valor Pago">PAGO</span> {(parseFloat(dados.saldo[0].total_pago) > 0 ? <img src={historico} onClick={()=>{Helper.VerificaLancamentos()}} className="cs" title="Clique aqui para ver os lançamentos realizados" /> :'')}</div>
           <div class="w-1/3 h-8 flex items-center justify-center border" title="Saldo Devedor">SALDO</div>
           <div class="w-1/3 h-8 flex items-center justify-center borderr"></div>
       </div>
   
       <div class="flex w_atv_celular tx10 tmTotais">
           <div class="w-1/3 h-[30px] flex items-center justify-center" title="Valor Total">
               <input type="text" name="total" id="total" data-valor={dados.saldo[0].total_inscricao.replace("-","")} value={`R$ ${(parseFloat(dados.saldo[0].total_inscricao)*100/100).toLocaleString('pt-br', {minimumFractionDigits: 2})}`}  class="h-full w-full text-center border txred tx10" />
           </div>
           <div class="w-1/3 h-[30px] flex items-center justify-center">
               <input type="text" name="pago" id="pago" data-valor={dados.saldo[0].total_pago.replace("-","")} value={`R$ ${(parseFloat(dados.saldo[0].total_pago)*100/100).toLocaleString('pt-br', {minimumFractionDigits: 2})}`} class="h-full w-full text-center border txblue tx10" />
           </div>
           <div class="w-1/3 h-[30px] flex items-center justify-center"  title="Saldo Devedor">
               <input type="text" name="saldo" id="saldo" data-valor={dados.saldo[0].saldo} value={`R$ ${(parseFloat(dados.saldo[0].saldo)*100/100).toLocaleString('pt-br', {minimumFractionDigits: 2})}`} class={`h-full w-full text-center border tx10 ${(dados.saldo[0].saldo < 0 ? 'txred':'txblue')}`} />
           </div>
           <div class="w-1/3 h-[30px] flex items-center justify-center borderb borderr">
                
           </div>
       </div>
</div>
     
         </form>
          

            <div className="w-1/4 p-1 flex flex-col items-center cad_celular">
                        <div className="w-full py-2 px-4 rounded mb-3 text-center">
                            
                                <table className="table-auto w-full left_celular">
                                    <tbody>
                                        <tr className="bg-green-600">
                                            <td className="border px-4 py-2 text-white font-bold"><b>INSCRIÇÃO</b></td>
                                        </tr>
                                        <tr>
                                            <td className="border px-4 py-2 text-xl">
                                                {dados.cadastro[0].cod_inscricao}
                                            </td>
                                        </tr>


                                    </tbody>
                                </table>

                        </div>
  
                <input type='hidden' id="modo_campo" value="sintetico" />
                <button className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-3 btn25" onClick={(ev) => {Helper.AbreModal(processahistorio)}} title="Clique aqui para ver o histórico">Histórico</button>
                <button className="w-full fundoCadastro text-white font-bold py-2 px-4 rounded mb-3 btn25 btn25_celular" onClick={(ev) => {window.location.href='/Cadastro'}}><span id="tx_modo_campo" title="Clique aqui para alterar o cadastro">Alterar Cadastro</span></button>
                <button className="w-full bg-orange-400 hover:bg-orange-600 text-white font-bold rounded mb-3  btn25" onClick={()=>{window.location.href='/VendaRapida'}} title="Ir para tela do Venda Rápida">Venda Rápida</button>
                <span className="text-blue-700 font-bold text-sm">Tipo Credencial</span>
                <select 
                className="w-full text-blue-500 font-bold rounded border text-center h30 f12_celular"
                name="tipo_credencial" 
                id="tipo_credencial" 
                data_obrigatorio="S" 
                data-validacao = ""
                data-tipo = "tipo_credencial"
                data-campo = "tipo_credencial"
                title="Tipo Credencial"
                data-cod_campo="tipo_credencial" onChange={() => {Helper.TrocaTipoCredencial(document.getElementById("categoria").value);}}>
                    {(processatipos.length >1 ? <option value="">Selecione</option>:'')}
                    {processatipos}
               
                </select>
                
                
                <table title="Histórico de lançamentos"><tr><td><span className="text-blue-700 font-bold">Pagamento</span></td></tr></table>
                <p className="w-full h10"></p>
                {/*dados.formas_pgto.map((f) => (
                        f.forma_pgto
                ))*/}
                {(parseFloat(dados.saldo[0].saldo) >=0 && dados.cadastro[0].produtos ?
                <button className="w-full bg-black text-white font-bold py-2 px-4 rounded mb-3" onClick={() => { Helper.AbreUrl(dados.cadastro[0].url_imprimir)}}>Imprimir</button>
                : <span class="f10_alert">Para impressão da Credencial, é necessário o saldo ser 0 ou positivo </span>)}
            </div>
            <div className="w-1/3 p-4 remove_celular text-center">
           
            </div>
        </div>   
        <Rodape passo="4" />
        </>
    )

    

   
}

export default Inscricao;