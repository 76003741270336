import Cadastro from "Components/Cadastro";
import Inscricao from "Components/Inscricao";
import Pesquisa from "Components/Pesquisa";
import VendaRapida from "Components/VendaRapida";
import Helper from "Helper";


import { BrowserRouter, Route, Routes } from "react-router-dom";

function AppRoutes(){
    const queryParams = new URLSearchParams(window.location.search);
    let token = queryParams.get('id');
    let md = queryParams.get('md');
    let idioma = queryParams.get('idioma');
    if(undefined == token || null == token || token == ""){
        token =  sessionStorage.getItem('token');
        md =  sessionStorage.getItem('md');
        idioma = sessionStorage.getItem('idioma');
    }
  
    if(undefined != token && null != token && token !=""){
        sessionStorage.setItem('token',token);
        sessionStorage.setItem('md', md);
        sessionStorage.setItem('idioma', (idioma?idioma:''));
    }
    if(undefined == token || null == token || token == ""){
        alert("Link Expirado, acesse somente o link principal");
        return false;
    }
    if(undefined == sessionStorage.getItem('usser_ok') || null == sessionStorage.getItem('usser_ok') || sessionStorage.getItem('usser_ok') !="05121986" || undefined == sessionStorage.getItem('token') || null == sessionStorage.getItem('token')  || sessionStorage.getItem('token') ==""){
        Helper.AbreLogin();
    }
   
    return (
        <>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Pesquisa />}/>
                <Route path="/Pesquisa" element={<Pesquisa />}/>
                <Route path="/Cadastro" element={<Cadastro />}/>
                <Route path="/VendaRapida" element={<VendaRapida />}/>
                <Route path="/Inscricao" element={<Inscricao />}/>
            </Routes>
        </BrowserRouter>
        <div className="versao" id="versao">Versão 1.1.3</div>
        </>
    )
}

export default AppRoutes;