import React, { useRef, useState } from 'react';
import swal from 'sweetalert';
import defaultFoto from "./foto.png";
import { API_BASE } from 'API';
let foto = defaultFoto; 

const CameraComponent = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);

  const startCamera = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        const backCamera = videoDevices.find(device => device.label.toLowerCase().includes('back')) || videoDevices[0];

        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: backCamera.deviceId ? { exact: backCamera.deviceId } : undefined }
        });

        setStream(stream);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }
      } catch (error) {
        console.error('Erro ao acessar a camera', error);
        swal('Error', 'Não foi possível acessar a câmera, verifique as permissões do dispositivo.', 'error');
      }
    } else {
      swal('Error', 'Câmera não suportada pelo navegador', 'error');
    }
  };

  const stopCamera = () => {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      setStream(null);
    }
  };

  const capturePhoto = () => {
    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext('2d');
      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;
      context.drawImage(videoRef.current, 0, 0);
      const dataUrl = canvasRef.current.toDataURL('image/png');
      setCapturedImage(dataUrl);
      return dataUrl;
    }
    return null;
  };

  const dataURLToBlob = (dataUrl) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const sendPhoto = async (dataUrl) => {
    const blob = dataURLToBlob(dataUrl);
    const formData = new FormData();
    formData.append('file', blob, 'photo.png');

    try {
      let url = API_BASE+"uploadArquivo.php?lng=BR&campo=IMG_FOTO&access_token="+sessionStorage.getItem('token')+"&id_user="+sessionStorage.getItem('nome_usser')+"&cod_usser="+sessionStorage.getItem('cod_usser');
      if("dev" ==  sessionStorage.getItem('md') || "DEV" ==  sessionStorage.getItem('md')){
         console.log('Chamada UploadArquivo:',url);
      }
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      if("dev" ==  sessionStorage.getItem('md') || "DEV" ==  sessionStorage.getItem('md')){
       console.log('result:', result);
      }
      if(undefined != result && null != result && result.url !=""){
        document.getElementById("img_foto").setAttribute("src",result.url);
        document.getElementById("img_foto").setAttribute("data-arquivo",result.arquivo);
        sessionStorage.setItem("foto",result.arquivo);
        swal('Success', 'Para concluir as alterações, clique em avançar para atualizar o cadastro com a nova foto', 'success').then(() => {
          stopCamera(); // Close the camera after successful upload
        });
      }
      else{
        swal('Error', 'Falha no recebimento do link da imagem', 'error');
      }
    } catch (error) {
      console.error('Erro no upload da imagem', error);
      swal('Error', 'Falha ao tentar fazer o upload da imagem', 'error');
    }
  };

  const openCameraModal = () => {
    swal({
      title: "Posicione abaixo a foto",
      content: {
        element: "div",
        attributes: {
          id: "camera-container",
          innerHTML: `
            <div>
              <video id="video" width="100%" autoplay playsinline></video>
              <canvas id="canvas" style="display: none;"></canvas>
              <div id="capture-controls" class="mt-4"></div>
            </div>
          `
        }
      },
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false
    }).then((value) => {
      if (value === null) { // Handle modal close using backdrop click or ESC key
        stopCamera();
        if (videoRef.current) {
          videoRef.current.srcObject = null;
        }
      }
    });

    // Usar um callback para garantir que o DOM está pronto antes de iniciar a câmera
    setTimeout(() => {
      videoRef.current = document.getElementById('video');
      canvasRef.current = document.getElementById('canvas');
      startCamera();

      const captureControls = document.getElementById('capture-controls');
      const captureButton = document.createElement('button');
      captureButton.innerText = 'Tirar';
      captureButton.className = 'bg-blue-500 text-white px-4 py-2 rounded';
      captureButton.onclick = () => {
        const dataUrl = capturePhoto();
        if (dataUrl) {
          displayCapturedImage(dataUrl);
        }
      };

      const closeButton = document.createElement('button');
      closeButton.innerText = 'Fechar';
      closeButton.className = 'bg-red-500 text-white px-4 py-2 rounded ml-2';
      closeButton.onclick = () => {
        stopCamera();
        swal.close(); // Close the SweetAlert modal
        if (videoRef.current) {
          videoRef.current.srcObject = null;
        }
      };

      captureControls.appendChild(captureButton);
      captureControls.appendChild(closeButton);
    }, 100);
  };

  const displayCapturedImage = (dataUrl) => {
    swal({
      title: "Foto Capturada",
      text: "",
      icon: dataUrl,
      buttons: {
        retake: {
          text: "Tirar outra",
          value: "retake",
          className: 'swal-button--danger'
        },
        send: {
          text: "Salvar",
          value: "send",
        }
      }
    }).then((value) => {
      switch (value) {
        case "retake":
          openCameraModal();
          break;
        case "send":
          sendPhoto(dataUrl);
          break;
        default:
          stopCamera();
      }
    });
  };
  if(undefined != sessionStorage.getItem("url_arquivo") && null != sessionStorage.getItem("url_arquivo") && "" != sessionStorage.getItem("url_arquivo")){
    foto = sessionStorage.getItem("url_arquivo");
  }
 

  return (
    <div>
    <center>
      {(
      undefined != sessionStorage.getItem("url_arquivo") && null != sessionStorage.getItem("url_arquivo") && "" != sessionStorage.getItem("url_arquivo") ?
      <img src={foto} id="img_foto" data-arquivo={sessionStorage.getItem("nomeImagem")} className="w-1/2 h-auto mb-4"  />
      :
      <img src={foto} id="img_foto" data-arquivo={sessionStorage.getItem("nomeImagem")} className="w-1/2 h-auto mb-4"  onClick={openCameraModal} />
      )}
    </center>
  </div>
  );
};

export default CameraComponent;
