import Helper from 'Helper';
import React from 'react';
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';

function Rodape({passo},{modificado}){
    let pagina ='#';
    if(passo == '1'){
        pagina = "/Cadastro";
    }
    else if(passo == '2'){
        pagina = "/VendaRapida";
    }
    else if(passo == '3'){
        pagina = "/Inscricao";
    }
    return (
       <>
       <div className={`flex justify-center pdt10 ${(passo==1 || passo==2 ?' py-6 mt-10 ':'')}`}>
            <div className="flex items-center">
                <div className="bg-white border border-blue-500 w-20 h-8 ml-2 flex items-center justify-center"><button><a href="/">Início</a></button></div>
        
            </div>

        <div className="flex items-center">
            <div className={`border border-blue-500 w-8 h-8 ml-2 flex items-center justify-center ${(passo==1?'bg-blue-500 text-white':'bg-white')}`}><a href="/" title='Pesquisa'>1</a></div>
       
        </div>
        <div className="flex items-center">
            <div className={`border border-blue-500 w-8 h-8 ml-2 flex items-center justify-center ${(passo==2?'bg-blue-500 text-white':'bg-white')}`}><a href={(undefined != sessionStorage.getItem('cod_inscricao') && sessionStorage.getItem('cod_inscricao') != ""?"/Cadastro":'#')} title='Cadastro'>2</a></div>
           
        </div>
        <div className="flex items-center">
            <div className={`border border-blue-500 w-8 h-8 ml-2 flex items-center justify-center ${(passo==3?'bg-blue-500 text-white':'bg-white')}`} 
              onClick={(ev) => {
                const codInscricao = sessionStorage.getItem('cod_inscricao');
                //('modificado:',sessionStorage.getItem('modificado'));
                if (codInscricao !== undefined && codInscricao !== "" && passo == 2 && (sessionStorage.getItem('modificado') == true || sessionStorage.getItem('modificado') == "true")) {
                   
                  Helper.validaInscricao(3);
                }
                else{
                    if (codInscricao !== undefined && codInscricao !== "")
                        { 
                            window.location.href="/VendaRapida";
                        }
                }
              }}
            ><a href='#' title='VendaRapida'>3</a></div>
            
        </div>
        <div className="flex items-center">
            <div className={`border border-blue-500 w-8 h-8 ml-2 flex items-center justify-center ${(passo==4?'bg-blue-500 text-white':'bg-white')}`}
            onClick={(ev) => {
                const codInscricao = sessionStorage.getItem('cod_inscricao');
                if (codInscricao !== undefined && codInscricao !== "" && passo == 2 && (sessionStorage.getItem('modificado') == true || sessionStorage.getItem('modificado') == "true")) {
                  Helper.validaInscricao(4);
                }
                else{
                    if (parseFloat(sessionStorage.getItem("saldo")) >= 0 && codInscricao !== undefined && codInscricao !== "")
                        { 
                            window.location.href="/Inscricao";
                        }
                        else{
                            swal("Verifique se foi lançado o produto ou se o saldo não está negativo","","error");
                        }
                }
              }}
            ><a href='#' title='Inscrição'>4</a></div>
          
        </div>
       
        
    </div>
       </>
    )
}

export default Rodape;