import Helper from "Helper";

function Topo({texto, cor_fundo, cor_texto}){
    return (
        <>
        <nav className={"h-[25px] "+cor_fundo+ " "+cor_texto}>
      <div className="container mx-auto flex justify-between items-center">
      <h1>{sessionStorage.getItem('nome_evento')}</h1>
      
        <button
          onClick={(ev) => {Helper.Sair()}}         
          className="h-[20px] rounded hover:bg-blue-700 f10"
          title="Clique aqui para sair"
        >
          {sessionStorage.getItem("nome_usser")} - Logout
        </button>
      </div>
    </nav>
       
        <div className={"flex h-[35px]  justify-center items-center text-4xl font-bold "+cor_fundo+ " "+cor_texto}>
            <h1 className="topo">{texto}</h1>
        </div>
        </>
    )
}

export default Topo;